import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import ActivityLogsList from "../../../components/activities/ActivityLogsList";
import DocumentListMetrics from "../../../components/documents/DocumentListMetrics";
import MeetingsList from "../../../components/meetings/MeetingsList";
import { ActionMode } from "../../../models/Actions";
import { openModal, showNotification } from "../../../redux/currentApp/slice";
import {
  selectCurrentDocument,
  selectCurrentDocumentMode,
  selectShowManagerEvaluation
} from "../../../redux/currentDocument/selectors";
import { selectCurrentEmployee } from "../../../redux/currentEmployee/selectors";

const mapStateToProps = createStructuredSelector({
  employee: selectCurrentEmployee,
  document: selectCurrentDocument,
  mode: selectCurrentDocumentMode,
  showManagerEvaluation: selectShowManagerEvaluation
});

const mapDispatchToProps = {
  notify: showNotification,
  openModal
};

type OwnProps = ReduxProps;

const PerformanceOverview: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { document, employee, mode, showManagerEvaluation, notify, openModal } =
    props;

  const metrics = {
    objectives: {
      current: document?.objectives?.length ?? 0
    },
    competences: {
      current: document?.competences?.length ?? 0
    },
    developmentActions: {
      current: document?.developmentActions?.length ?? 0
    },
    values: {
      current: document?.values?.length ?? 0
    }
  };

  return (
    <>
      {/* Documents metrics */}
      <div className="mt-6">
        <h2 className="text-base font-semibold leading-7">
          {t("metrics.title")}
        </h2>
        <DocumentListMetrics metrics={metrics} />
      </div>
      {document != null && (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-16 mx-auto max-w-7xl pt-11">
          <MeetingsList
            employee={employee}
            document={document}
            mode={mode as ActionMode}
            openModal={openModal}
            notify={notify}
          />
          {showManagerEvaluation && (
            <ActivityLogsList
              documentId={document.formUUID}
              className="lg:col-span-6 xl:col-span-6"
            />
          )}
        </div>
      )}
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceOverview);
