import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import InputNumber from "../../../../components/forms/InputNumber";
import InputRichText from "../../../../components/forms/InputRichText";
import ObjectiveScoreSelector from "../../../../components/selectors/ObjectiveScoreSelector";
import { BaseObjective } from "../../../../models/Form/BaseObjective";
import { ObjectiveScore } from "../../../../models/Form/ObjectiveScore";
import { NotificationType } from "../../../../models/Notification";
import { UserRole } from "../../../../models/User";
import { showNotification } from "../../../../redux/currentApp/slice";
import {
  isLoadingAddDocumentObjectiveEvaluation,
  selectAddDocumentObjectiveEvaluationError,
  selectAddDocumentObjectiveEvaluationStatus
} from "../../../../redux/currentDocument/objectives/selectors";
import {
  addDocumentObjectiveEvaluation,
  clearAddDocumentObjectiveEvaluationStatus
} from "../../../../redux/currentDocument/objectives/slice";
import { selectCurrentEmployeeLang } from "../../../../redux/currentEmployee/selectors";
import WarningBanner from "../../../../components/banners/WarningBanner";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingAddDocumentObjectiveEvaluation,
  status: selectAddDocumentObjectiveEvaluationStatus,
  formError: selectAddDocumentObjectiveEvaluationError,
  lang: selectCurrentEmployeeLang
});

const mapDispatchToProps = {
  addEvaluation: addDocumentObjectiveEvaluation,
  clearStatus: clearAddDocumentObjectiveEvaluationStatus,
  notify: showNotification
};

type OwnProps = {
  documentId: string;
  objective: BaseObjective;
  isManager: boolean;
  isEmployee: boolean;
  onCancel: () => void;
};

type Props = OwnProps & ReduxProps;

const EditObjectiveEvaluation: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    documentId,
    objective,
    isManager,
    isEmployee,
    onCancel,
    isLoading,
    status,
    formError,
    lang,
    addEvaluation,
    clearStatus,
    notify
  } = props;
  const role: UserRole = isManager
    ? UserRole.MANAGER
    : isEmployee
      ? UserRole.EMPLOYEE
      : UserRole.COACH;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [objectiveScore, setObjectiveScore] = useState<ObjectiveScore | null>(
    objective.evaluation?.[role].score
  );

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      clearStatus();
      onCancel();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    documentId,
    notify,
    onCancel,
    t,
    tCommon
  ]);

  const initialState = {
    weight: objective.weight ?? 0,
    scoreWeight: objective.evaluation?.[role].scoreWeight ?? 0,
    comment: objective.evaluation?.[role].comment ?? ""
  };

  const getValidationSchema = (score: ObjectiveScore | null) => {
    const maxWeight = score ? score.maxValue : 200;

    return Yup.object({
      weight: Yup.number().max(100, t("form.errors.weight")),
      scoreWeight: Yup.number()
        .max(maxWeight, `Maximum allowed weight is ${maxWeight}`)
        .required("Required"),
      comment: Yup.string()
    });
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={getValidationSchema(objectiveScore)}
      onSubmit={async (values) => {
        if (objectiveScore != null) {
          addEvaluation({
            formUUID: documentId,
            objectiveId: objective._id,
            data: {
              score: objectiveScore._id!,
              ...values
            }
          });
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="sm:flex sm:items-start">
            <div className="space-y-12 w-full">
              <div className="border-b border-gray-900/10 pb-5 mb-5">
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {objective.relatedType.ukey === 5 && (
                    <div className="col-span-full">
                      <WarningBanner
                        message={t("evaluation.otherRealisations")}
                        isHtml
                      />
                    </div>
                  )}

                  {/* Objective type selector */}
                  <div className="col-span-3 col-start-1">
                    <label
                      htmlFor="objectiveType"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t(`evaluation.score`)}
                    </label>
                    <ObjectiveScoreSelector
                      lang={lang!}
                      defaultValue={objectiveScore ?? undefined}
                      onSelectItem={(item) => {
                        setObjectiveScore(item);
                        setFieldValue("scoreWeight", item.value);
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <InputNumber
                      label={t(`evaluation.scoreWeight`)}
                      name="scoreWeight"
                      isPercent
                      min={objectiveScore?.minValue ?? 0}
                      max={objectiveScore?.maxValue ?? 100}
                    />
                  </div>
                  {role === UserRole.MANAGER && (
                    <div className="col-span-2">
                      <InputNumber
                        label={t(`fields.weight`)}
                        name="weight"
                        isPercent
                        min={0}
                        max={100}
                      />
                    </div>
                  )}

                  {/* Short description */}
                  <div className="col-span-full">
                    <InputRichText
                      innerRef={editorRef}
                      label={t(`evaluation.comment`)}
                      name="comment"
                      initialValue={initialState.comment}
                      onChange={(value: string) => {
                        setFieldValue("comment", value);
                      }}
                      helperText={t(`evaluation.${role}.helpers.comment`)}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isSubmitting || !objectiveScore}
                    onClick={onCancel}
                  >
                    {tCommon("actions.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting || !objectiveScore}
                  >
                    {tCommon("actions.save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditObjectiveEvaluation);
