import { FC, Fragment, ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { CompetenceScore } from "../../models/Form/CompetenceScore";
import { SupportedLanguages } from "../../models/LangField";
import { selectCompetenceScores } from "../../redux/currentApp/selectors";
import { classNames } from "../../utils/styles";

const mapStateToProps = createStructuredSelector({
  competenceScores: selectCompetenceScores
});

type OwnProps = {
  lang: SupportedLanguages;
  defaultValue?: CompetenceScore;
  exclude?: string[];
  onSelectItem?: (value: CompetenceScore) => void;
} & ReduxProps;

const CompetenceScoreSelector: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const {
    lang,
    defaultValue = null,
    exclude = [],
    competenceScores,
    onSelectItem
  } = props;

  const [selected, setSelected] = useState<CompetenceScore | null>(
    defaultValue
  );

  const onChangeHandler = (item: CompetenceScore) => {
    setSelected(item);

    if (onSelectItem) onSelectItem(item);
  };

  const selectorLabel = useMemo(() => {
    const label = selected?.name?.[lang];

    return label != null
      ? label.length > 50
        ? label.slice(0, 50) + "..."
        : label
      : t("common.selectors.competenceScores");
  }, [selected, lang, t]);

  const filteredCompetenceScores = useMemo(() => {
    return competenceScores
      ?.filter((competenceScore) => !exclude?.includes(competenceScore._id))
      .sort((a, b) => a.order - b.order);
  }, [competenceScores, exclude]);

  return (
    <Listbox
      value={selected}
      onChange={onChangeHandler}
      disabled={!onSelectItem}
    >
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <ListboxButton
              className={classNames(
                "relative cursor-default w-50 rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
                !onSelectItem ? "cursor-not-allowed opacity-50" : ""
              )}
            >
              <span className="inline-flex justify-between w-full truncate">
                <span className="block font-bold truncate">
                  {selectorLabel}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-w-sm max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {(filteredCompetenceScores || []).map((item) => (
                  <ListboxOption
                    key={item._id ?? "competencetype"}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item?.name?.[lang]}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              focus ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const withConnect = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(CompetenceScoreSelector);
