import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ActivityLogsList from "../../../../components/activities/ActivityLogsList";
import ActionButton from "../../../../components/buttons/ActionButton";
import CourseCatalog from "../../../../components/integrations/riseUp/CourseCatalog";
import NotesList from "../../../../components/notes/NotesList";
import { useRemoveCourseFromDevAction } from "../../../../hooks/integrations/riseUp/useRemoveCourseFromDevAction";
import { ActionMode } from "../../../../models/Actions";
import { BaseDevelopmentAction } from "../../../../models/Form/BaseDevelopmentAction";
import { BaseForm, Phase } from "../../../../models/Form/BaseForm";
import { RiseUpCourse } from "../../../../models/integrations/RiseUp/RiseUpCourse";
import { ActivityLogResourceType } from "../../../../models/Logs/ActivityLog";
import {
  NotificationConfig,
  NotificationType
} from "../../../../models/Notification";
import { User } from "../../../../models/User";
import {
  addDocumentDevActionCourse,
  removeDocumentDevActionCourse
} from "../../../../redux/currentDocument/devActions/slice";
import DevActionHeader from "./DevActionHeader";
import DevActionMeta from "./DevActionMeta";
import DevActionEvaluations from "./DevActionEvaluations";

const mapDispatchToProps = {
  addCourse: addDocumentDevActionCourse,
  removeCourse: removeDocumentDevActionCourse
};

type OwnProps = {
  currentUser: User;
  employeeId: string;
  document: BaseForm;
  devAction: BaseDevelopmentAction;
  showManagerEvaluation: boolean;
  mode?: ActionMode;
  notify: (notification: NotificationConfig) => void;
  openModal: (modal: any) => void;
};

const PerformanceDevActionDetail: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "devActions"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    currentUser,
    employeeId,
    document,
    devAction,
    showManagerEvaluation,
    mode = ActionMode.READ_ONLY,
    notify,
    removeCourse,
    openModal
  } = props;
  const {
    formUUID: documentId,
    relatedManager,
    relatedEmployee,
    relatedPhase
  } = document;
  const isOwner = employeeId === devAction.createdBy._id;
  const isManager = employeeId === relatedManager._id;
  const isEmployee = employeeId === relatedEmployee._id;

  const openEditDevActionModal = () => {
    openModal({
      modal: {
        component: "EditDevActionModal",
        props: {
          documentId,
          employeeId,
          devAction
        }
      }
    });
  };

  const openRemoveDevActionModal = () => {
    openModal({
      modal: {
        component: "RemoveDevActionModal",
        props: {
          documentId,
          devActionId: devAction._id
        }
      }
    });
  };

  const openCourseDetailModal = (course: RiseUpCourse) => {
    openModal({
      modal: {
        component: "CourseDetailModal",
        props: {
          documentId,
          devActionId: devAction._id,
          course,
          isManager
        }
      }
    });
  };

  const mutationUnlinkCourse = useRemoveCourseFromDevAction(
    documentId,
    devAction._id
  );

  const onUnlinkCourse = (course: RiseUpCourse) => {
    if (course != null) {
      mutationUnlinkCourse.mutate(course._id);
      removeCourse({
        devActionId: devAction._id,
        courseId: course._id
      });

      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.saveSuccess"),
        message: tCommon("notifications.courseUnlinked")
      });
    }
  };

  return (
    <main className="py-10">
      <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <DevActionHeader
          devAction={devAction}
          onEdit={
            mode === ActionMode.EDIT
              ? () => openEditDevActionModal()
              : undefined
          }
        />
      </div>

      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <DevActionMeta
            documentId={document.formUUID}
            devAction={devAction}
            lang={currentUser.lang}
            mode={mode}
            memberId={document.relatedEmployee?.riseupUser?._id}
            notify={notify}
            showBeta={currentUser.isAdmin || currentUser.isSpecialGuest}
          />

          {/* Courses */}
          {(currentUser.isAdmin || currentUser.isSpecialGuest) &&
            devAction.courses.length > 0 && (
              <CourseCatalog
                courses={devAction.courses}
                onLinkCourse={
                  relatedPhase === Phase.target
                    ? openCourseDetailModal
                    : undefined
                }
                onUnlinkCourse={
                  relatedPhase === Phase.target ? onUnlinkCourse : undefined
                }
              />
            )}

          {document.relatedPhase === Phase.review && (
            <DevActionEvaluations
              document={document}
              devAction={devAction}
              isManager={isManager}
              isEmployee={isEmployee}
            />
          )}

          {/* Comments */}
          <NotesList
            currentUser={currentUser}
            documentId={documentId}
            employeeId={employeeId}
            resourceType="development-action"
            resourceId={devAction._id}
            mode={mode}
            notify={notify}
          />
        </div>

        <div>
          {(showManagerEvaluation || isManager) && (
            <ActivityLogsList
              documentId={documentId}
              resourceType={ActivityLogResourceType.DEV_ACTION}
              resourceId={devAction._id}
            />
          )}
          {isOwner && mode === ActionMode.EDIT && (
            <div className="mt-4">
              <ActionButton
                label={t("actions.delete")}
                onClick={openRemoveDevActionModal}
                colorVariant="red"
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceDevActionDetail);
