import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { BaseForm } from "../../models/Form/BaseForm";

type OwnProps = {
  document: BaseForm;
};

const DocumentWarnings: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { document } = props;
  const { objectives } = document;

  const totalWeight = objectives.reduce((acc, obj) => acc + obj.weight, 0);

  const id = `document-warnings-${Math.random().toString(36).substring(7)}`;

  return (
    <>
      {totalWeight !== 100 ? (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-orange-500"
          aria-hidden="true"
          data-tooltip-id={id}
          data-tooltip-html={t("teamlist.warnings.totalWeight", {
            total: totalWeight
          })}
        />
      ) : null}
      {totalWeight !== 100 ? <Tooltip id={id} /> : null}
    </>
  );
};

export default DocumentWarnings;
