import { CancelToken } from "axios";

import {
  BaseDevelopmentAction, DevelopmentActionTheme, RawBaseDevelopmentAction,
  RawBaseDevelopmentActionEvaluation
} from "../models/Form/BaseDevelopmentAction";
import API, { ApiV1 } from "../utils/api";

/**
 * Retrieves the development action themes from the server.
 * @param cancelToken - The cancel token used to cancel the request.
 * @returns A promise that resolves to an array of DevelopmentActionTheme objects.
 */
export const getDevelopmentActionThemes = async (
  cancelToken: CancelToken
): Promise<DevelopmentActionTheme[]> => {
  const url = "/developmentactionthemes";

  try {
    const response = await ApiV1.get<{ data: DevelopmentActionTheme[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Creates a new development action.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param payload - The payload containing the raw base development action data.
 * @returns A promise that resolves to the created base development action.
 */
export const createNewDevAction = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseDevelopmentAction
): Promise<BaseDevelopmentAction> => {
  const url = `/form/${formUUID}/development-action`;

  try {
    const response = await API.post<{ data: BaseDevelopmentAction }>(
      url,
      payload,
      {
        cancelToken
      }
    );

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates a development action.
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action.
 * @param payload - The payload containing the updated development action data.
 * @returns A promise that resolves to the updated development action.
 */
export const updateDevAction = async (
  cancelToken: CancelToken,
  formUUID: string,
  devActionId: string,
  payload: RawBaseDevelopmentAction
): Promise<BaseDevelopmentAction> => {
  const url = `/form/${formUUID}/development-action/${devActionId}`;

  try {
    const response = await API.put<{ data: BaseDevelopmentAction }>(
      url,
      payload,
      {
        cancelToken
      }
    );

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Deletes a development action.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action to delete.
 * @returns A Promise that resolves with void when the deletion is successful, or rejects with an error if an error occurs.
 */
export const deleteDevAction = async (
  cancelToken: CancelToken,
  formUUID: string,
  devActionId: string
): Promise<void> => {
  const url = `/form/${formUUID}/development-action/${devActionId}`;

  try {
    await API.delete(url, {
      cancelToken
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Adds a course to a development action.
 * 
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action.
 * @param courseId - The ID of the course to add.
 * @returns A promise that resolves with void if the course is added successfully, or rejects with an error if an error occurs.
 */
export const addCourseToDevAction = async (
  formUUID: string,
  devActionId: string,
  courseId: string
): Promise<void> => {
  const url = `/form/${formUUID}/development-action/${devActionId}/courses`;

  try {
    await API.post(url, { courseId });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};


/**
 * Removes a course from a development action.
 * 
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action.
 * @param courseId - The ID of the course to be removed.
 * @returns A promise that resolves with void.
 */
export const removeCourseFromDevAction = async (
  formUUID: string,
  devActionId: string,
  courseId: string
): Promise<void> => {
  const url = `/form/${formUUID}/development-action/${devActionId}/courses/${courseId}`;

  try {
    await API.delete(url);
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Adds a course registration to a development action.
 * 
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action.
 * @param registrationId - The ID of the course registration to add.
 * @returns A promise that resolves with void if the course is added successfully, or rejects with an error if an error occurs.
 */
export const addCourseRegistrationToDevAction = async (
  formUUID: string,
  devActionId: string,
  registrationId: string
): Promise<void> => {
  const url = `/form/${formUUID}/development-action/${devActionId}/courseregistrations`;

  try {
    await API.post(url, { registrationId });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};


/**
 * Removes a course registration from a development action.
 * 
 * @param formUUID - The UUID of the form.
 * @param devActionId - The ID of the development action.
 * @param registrationId - The ID of the course registration to be removed.
 * @returns A promise that resolves with void.
 */
export const removeCourseRegistrationFromDevAction = async (
  formUUID: string,
  devActionId: string,
  registrationId: string
): Promise<void> => {
  const url = `/form/${formUUID}/development-action/${devActionId}/courseregistrations/${registrationId}`;

  try {
    await API.delete(url);
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates the score of a development action.
 *
 * @param cancelToken - The token to cancel the request if needed.
 * @param formUUID - The unique identifier of the form.
 * @param devActionId - The unique identifier of the development action.
 * @param payload - The payload containing the evaluation data.
 * @returns A promise that resolves to the updated development action.
 * @throws Will throw an error if the request fails.
 */
export const updateDevActionScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  devActionId: string,
  payload: RawBaseDevelopmentActionEvaluation
): Promise<BaseDevelopmentAction> => {
  const url = `/form/${formUUID}/development-action/${devActionId}/score`;

  try {
    const response = await API.patch<{ data: BaseDevelopmentAction }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};