import { FC, ReactElement, useMemo } from "react";

import { CalendarIcon } from "@heroicons/react/20/solid";

import { useMeetings } from "../../hooks/meetings/useMeetings";
import { BaseForm, Phase } from "../../models/Form/BaseForm";
import { formatISODateStr } from "../../utils/dates";
import DocumentBadge from "../documents/DocumentBadge";
import DocumentReviewStatus from "../documents/DocumentReviewStatus";
import DocumentStatus from "../documents/DocumentStatus";
import DocumentWarnings from "../documents/DocumentWarnings";

type OwnProps = {
  document: BaseForm;
};

const TeamlistStatus: FC<OwnProps> = (props): ReactElement => {
  const { document } = props;

  // Get document meetings
  const { data: meetings, isLoading } = useMeetings(
    document.formUUID,
    document.isActive
  );
  const lastMeeting = useMemo(() => {
    if (meetings == null || meetings.length === 0) {
      return null;
    }

    return meetings[0];
  }, [meetings]);

  return (
    <>
      <div className="mt-1 flex flex-none items-center gap-x-1">
        {document.relatedPhase === Phase.target ? (
          <DocumentStatus
            document={document}
            lastMeetingDate={lastMeeting?.meetingDate}
          />
        ) : (
          <DocumentReviewStatus
            document={document}
            lastMeetingDate={lastMeeting?.meetingDate}
          />
        )}
        {document.relatedPhase === Phase.target && (
          <DocumentBadge document={document} />
        )}
        <DocumentWarnings document={document} />
      </div>
      {!isLoading && lastMeeting && (
        <div className="mt-1 flex flex-none items-center gap-x-1 text-xs">
          <dt>
            <span className="sr-only">Upcoming meeting</span>
            <CalendarIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </dt>
          <dd>
            <time dateTime={lastMeeting.meetingDate}>
              {formatISODateStr(lastMeeting.meetingDate, "dd/MM/yy")}
            </time>
          </dd>
        </div>
      )}
    </>
  );
};

export default TeamlistStatus;
