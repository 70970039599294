import { CancelToken } from 'axios';

import {
  BaseCompetence, FunctionalCompetence, GenericCompetence, RawBaseCompetence,
  RawCompetenceEvaluation
} from '../models/Form/BaseCompetence';
import { CompetenceScore } from '../models/Form/CompetenceScore';
import API, { ApiV1 } from '../utils/api';

/**
 * Retrieves the functional competences for a given profile.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param profileId - The ID of the profile.
 * @returns A promise that resolves to an array of GenericCompetence objects.
 */
export const getFunctionalCompetences = async (
  cancelToken: CancelToken,
  profileId: string
): Promise<FunctionalCompetence[]> => {
  const url = `/functionalcompetences?profile=${profileId}`;

  try {
    const response = await ApiV1.get<{ data: FunctionalCompetence[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Retrieves the generic competences for a given profile.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param profileId - The ID of the profile.
 * @returns A promise that resolves to an array of GenericCompetence objects.
 */
export const getGenericCompetences = async (
  cancelToken: CancelToken,
  profileId: string
): Promise<GenericCompetence[]> => {
  const url = `/genericcompetences?profile=${profileId}`;

  try {
    const response = await ApiV1.get<{ data: GenericCompetence[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Creates a new competence.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param payload - The payload containing the raw base competence data.
 * @returns A promise that resolves to the created base competence.
 */
export const createNewCompetence = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseCompetence
): Promise<BaseCompetence> => {
  const url = `/form/${formUUID}/competence`;

  try {
    const response = await API.post<{ data: BaseCompetence }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates an existing competence.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param competenceId - The ID of the competence.
 * @param payload - The payload containing the raw base competence data.
 * @returns A promise that resolves to the updated base competence.
 */
export const updateCompetence = async (
  cancelToken: CancelToken,
  formUUID: string,
  competenceId: string,
  payload: RawBaseCompetence
): Promise<BaseCompetence> => {
  const url = `/form/${formUUID}/competence/${competenceId}`;

  try {
    const response = await API.put<{ data: BaseCompetence }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Deletes a competence.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @param formUUID - The UUID of the form.
 * @param competenceId - The ID of the competence.
 * @returns A promise that resolves to void.
 */
export const deleteCompetence = async (
  cancelToken: CancelToken,
  formUUID: string,
  competenceId: string
): Promise<void> => {
  const url = `/form/${formUUID}/competence/${competenceId}`;

  try {
    await API.delete(url, {
      cancelToken
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getCompetenceScores = async (
  cancelToken: CancelToken
): Promise<CompetenceScore[]> => {
  const url = "/competencescores";

  try {
    const response = await ApiV1.get<{ data: CompetenceScore[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}

/**
 * Updates the competence score for a given competence ID within a specific form.
 *
 * @param cancelToken - The token used to cancel the request if needed.
 * @param formUUID - The unique identifier of the form.
 * @param competenceId - The unique identifier of the competence.
 * @param payload - The data containing the raw competence evaluation.
 * @returns A promise that resolves to the updated BaseCompetence object.
 * @throws Will reject the promise with an error if the request fails.
 */
export const updateCompetenceScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  competenceId: string,
  payload: RawCompetenceEvaluation
): Promise<BaseCompetence> => {
  const url = `/form/${formUUID}/competence/${competenceId}/score`;

  try {
    const response = await API.patch<{ data: BaseCompetence }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};