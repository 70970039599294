import { CancelToken } from 'axios';

import {
  BaseValue, RawBaseValue, RawBaseValueEvaluation, ValueType
} from '../models/Form/BaseValue';
import API, { ApiV1 } from '../utils/api';

export const getValueTypes = async (
  cancelToken: CancelToken
): Promise<ValueType[]> => {
  const url = "/valuetypes";

  try {
    const response = await ApiV1.get<{ data: ValueType[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const createNewValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseValue
): Promise<BaseValue> => {
  const url = `/form/${formUUID}/values`;

  try {
    const response = await API.post<{ data: BaseValue }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  valueId: string,
  payload: RawBaseValue
): Promise<BaseValue> => {
  const url = `/form/${formUUID}/values/${valueId}`;

  try {
    const response = await API.put<{ data: BaseValue }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  valueId: string
): Promise<void> => {
  const url = `/form/${formUUID}/values/${valueId}`;

  try {
    await API.delete(url, {
      cancelToken
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates the score of a specific value in a form.
 *
 * @param cancelToken - The token used to cancel the request if needed.
 * @param formUUID - The unique identifier of the form.
 * @param valueId - The unique identifier of the value to be updated.
 * @param payload - The data to be sent in the request body.
 * @returns A promise that resolves to the updated `BaseValue` object.
 * @throws Will throw an error if the request fails.
 */
export const updateValueScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  valueId: string,
  payload: RawBaseValueEvaluation
): Promise<BaseValue> => {
  const url = `/form/${formUUID}/values/${valueId}/score`;

  try {
    const response = await API.patch<{ data: BaseValue }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};