import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PuzzlePieceIcon, RocketLaunchIcon } from "@heroicons/react/24/outline";

import { CompetenceScore } from "../../models/Form/CompetenceScore";
import { SupportedLanguages } from "../../models/LangField";
import DocumentEvaluationScore from "./DocumentEvaluationScore";

type OwnProps = {
  lang: SupportedLanguages;
  objectiveScore: number;
  competenceScore?: CompetenceScore;
};

const DocumentEvaluationScores: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { objectiveScore, competenceScore, lang } = props;

  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
      <DocumentEvaluationScore
        id={"objectives"}
        icon={RocketLaunchIcon}
        label={t("evaluations.globalScore")}
        score={`${objectiveScore}`}
        color={"teal"}
        isPercent
      />
      {competenceScore && (
        <DocumentEvaluationScore
          id={"competences"}
          icon={PuzzlePieceIcon}
          label={t("evaluations.globalCompetenceScore")}
          score={competenceScore.name?.[lang]}
          color={"orange"}
        />
      )}
    </dl>
  );
};

export default DocumentEvaluationScores;
