import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  ChatBubbleLeftRightIcon,
  StarIcon,
  TagIcon
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";

import { Phase } from "../../models/Form/BaseForm";
import { BaseValue } from "../../models/Form/BaseValue";
import { SupportedLanguages } from "../../models/LangField";
import { baseUrl } from "../../routes";

type OwnProps = {
  documentId: string;
  values: BaseValue[];
  lang: SupportedLanguages;
  currentPhase: Phase;
  showManagerEvaluation: boolean;
};

const ValuesTable: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const { documentId, values, lang, currentPhase, showManagerEvaluation } =
    props;

  const sortedValues = useMemo(() => {
    // Sort value by ukey
    return [...values].sort((a, b) => a.relatedType.ukey - b.relatedType.ukey);
  }, [values]);

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 text-left">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="w-full sm:w-4/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              {t("table.headers.title")}
            </th>
            <th
              scope="col"
              className="lg:w-4/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {t("table.headers.type")}
            </th>
            {/* <th
              scope="col"
              className="lg:w-2/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {t("table.headers.behaviors")}
            </th> */}
            {currentPhase === Phase.target && (
              <th
                scope="col"
                className="lg:w-2/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                {t("table.headers.notes")}
              </th>
            )}
            {currentPhase === Phase.review && (
              <>
                <th
                  scope="col"
                  className="lg:w-1/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("table.headers.employeeScore")}
                </th>
                {showManagerEvaluation && (
                  <th
                    scope="col"
                    className="lg:w-2/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("table.headers.managerScore")}
                  </th>
                )}
              </>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5 bg-white">
          {sortedValues.map((item, index) => (
            <tr key={item._id}>
              <td className="hidden py-4 pl-4 pr-4 text-sm sm:table-cell sm:pr-8">
                <Link
                  to={`${baseUrl}/performance/${documentId}/lead/${item._id}`}
                  className="font-medium text-gray-900"
                >
                  {item?.title ?? item.description.slice(0, 30)}
                </Link>
                {item.description ? (
                  <div className="mt-1 text-xs text-gray-500">
                    {item.description.slice(0, 30)}
                  </div>
                ) : null}
              </td>
              <td className="hidden py-4 pl-0 pr-4 text-sm sm:table-cell sm:pr-8">
                <div className="text-sm text-gray-900">
                  {item.relatedType.name[lang ?? "fr"]}
                  <div className="mt-1 text-xs text-gray-500">
                    {item.relatedType.definition[lang ?? "fr"]}
                  </div>
                </div>
              </td>
              {/* <td className="hidden py-4 text-sm sm:table-cell sm:pr-8">
                <div className="flex items-center px-2">
                  <TagIcon
                    className="h-6 w-6 text-yellow-600"
                    aria-hidden="true"
                  />
                  <span className="inline-flex items-center rounded-full bg-yellow-100 ml-1 px-2 py-1 text-xs font-medium text-yellow-700">
                    {item.relatedType.behaviors.length ?? 0}
                  </span>
                </div>
              </td> */}
              {currentPhase === Phase.target && (
                <td className="hidden py-4 text-center text-sm leading-6 text-gray-400 md:table-cell lg:pr-6">
                  <div className="flex items-center px-2">
                    <ChatBubbleLeftRightIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                    <span className="inline-flex items-center rounded-full bg-blue-100 ml-1 px-2 py-1 text-xs font-medium text-blue-700">
                      {item.notes.length ?? 0}
                    </span>
                  </div>
                </td>
              )}
              {currentPhase === Phase.review && (
                <>
                  <td className="py-4 text-center text-sm leading-6 sm:pr-2 lg:pr-6">
                    <div className="flex items-center px-2">
                      {item.evaluation.employee?.comment ? (
                        <StarIconSolid
                          className="h-6 w-6 text-orange-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <StarIcon
                          className="h-6 w-6 text-orange-600"
                          aria-hidden="true"
                        />
                      )}
                      {/* <span className="inline-flex items-center rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600">
                        {item.evaluation.employee?.score?.name?.[lang] ?? "-"}
                      </span> */}
                    </div>
                  </td>
                  {showManagerEvaluation && (
                    <td className="hidden py-4 text-center text-sm leading-6 text-gray-400 md:table-cell lg:pr-6">
                      <div className="flex items-center px-2">
                        {item.evaluation.manager?.comment ? (
                          <StarIconSolid
                            className="h-6 w-6 text-orange-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <StarIcon
                            className="h-6 w-6 text-orange-600"
                            aria-hidden="true"
                          />
                        )}
                        {/* <span className="inline-flex items-center rounded-full bg-orange-100 ml-1 px-2 py-1 text-xs font-medium text-orange-600">
                        {item.evaluation.manager?.score?.name?.[lang] ?? "-"}
                      </span> */}
                      </div>
                    </td>
                  )}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ValuesTable;
