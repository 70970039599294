import Axios, { CancelTokenSource } from 'axios';
import merge from 'lodash/merge';

import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';

import { CompetenceEvaluation } from '../../models/Form/BaseCompetence';
import { BaseForm } from '../../models/Form/BaseForm';
import { CompetenceScore } from '../../models/Form/CompetenceScore';
import {
  deleteDocumentCoach, getForm, setFormDiscussionDate, updateForm, updateFormScore,
  updateGlobalCompetenceScore, validateDocument
} from '../../services/FormService';
import { selectCompetenceScores } from '../currentApp/selectors';
import { setDocumentCompetences } from './competences/slice';
import { setDocumentDevActions } from './devActions/slice';
import { setDocumentObjectives } from './objectives/slice';
import { selectCurrentDocument } from './selectors';
import {
  addDocumentEvaluation, addDocumentEvaluationError, addDocumentEvaluationSuccess,
  addGlobalCompetenceEvaluation, addGlobalCompetenceEvaluationError,
  addGlobalCompetenceEvaluationSuccess, loadDocument, loadDocumentError, loadDocumentSuccess,
  removeDocumentCoach, removeDocumentCoachError, removeDocumentCoachSuccess, setDiscussionDate,
  setDiscussionDateError, setDiscussionDateSuccess, updateDocument, updateDocumentError,
  updateDocumentSuccess, validateDocumentEvaluation, validateDocumentEvaluationError,
  validateDocumentEvaluationSuccess
} from './slice';
import { setDocumentValues } from './values/slice';

function* getEmployeeDocumentsSaga(action: ReturnType<typeof loadDocument>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID } = action.payload;

  try {
    const data: BaseForm = yield call(getForm, signal.token, formUUID);

    yield all([
      put(loadDocumentSuccess({ data })),
      put(setDocumentObjectives({ objectives: data.objectives })),
      put(setDocumentCompetences({ competences: data.competences })),
      put(setDocumentDevActions({ devActions: data.developmentActions })),
      put(setDocumentValues({ values: data.values }))
    ]);
  } catch (error: any) {
    yield put(loadDocumentError(error));
  }
}

function* updateEmployeeDocumentSaga(
  action: ReturnType<typeof updateDocument>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(updateForm, signal.token, formUUID, data);

    yield put(
      updateDocumentSuccess({
        data: merge({}, currentDocument, data)
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(updateDocumentError({ error: message, payload: message }));
  }
}

function* setDiscussionDateSaga(action: ReturnType<typeof setDiscussionDate>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, discussionDate } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(setFormDiscussionDate, signal.token, formUUID, discussionDate);

    yield put(
      setDiscussionDateSuccess({
        data: {
          ...currentDocument,
          lastDiscussionDate: discussionDate.toISOString()
        }
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(setDiscussionDateError({ error: message, payload: message }));
  }
}

function* removeDocumentCoachSaga(
  action: ReturnType<typeof removeDocumentCoach>
) {
  const { formUUID, coachId } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(deleteDocumentCoach, formUUID, coachId);

    yield put(
      removeDocumentCoachSuccess({
        data: {
          ...currentDocument,
          performanceCoachs: currentDocument.performanceCoachs.filter(
            (coach) => coach._id !== coachId
          )
        }
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(removeDocumentCoachError({ error: message, payload: message }));
  }
}

function* addGlobalCompetenceEvaluationSaga(
  action: ReturnType<typeof addGlobalCompetenceEvaluation>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(updateGlobalCompetenceScore, signal.token, formUUID, data);

    const competenceScores: CompetenceScore[] = yield select(selectCompetenceScores);
    const globalCompetenceScore = competenceScores.find(
      (score) => score._id === data.score
    );

    yield put(
      addGlobalCompetenceEvaluationSuccess({
        data: {
          ...currentDocument,
          evaluation: {
            ...currentDocument.evaluation,
            globalCompetence: {
              comment: data.comment,
              modifiedAt: new Date().toISOString(),
              ...(globalCompetenceScore ? { score: globalCompetenceScore } : {})
            } as CompetenceEvaluation
          }
        }
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(addGlobalCompetenceEvaluationError({ error: message, payload: message }));
  }
}

function* addDocumentEvaluationSaga(
  action: ReturnType<typeof addDocumentEvaluation>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data, role } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(updateFormScore, signal.token, formUUID, data);

    yield put(
      addDocumentEvaluationSuccess({
        data: {
          ...currentDocument,
          evaluation: {
            ...currentDocument.evaluation,
            [role]: {
              comment: data.comment,
              career: data?.career ?? currentDocument.evaluation[role].career,
              modifiedAt: new Date().toISOString(),
            }
          }
        }
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(addDocumentEvaluationError({ error: message, payload: message }));
  }
}

function* validateDocumentEvaluationSaga(
  action: ReturnType<typeof validateDocumentEvaluation>
) {
  const { formUUID, role } = action.payload;
  const currentDocument: BaseForm = yield select(selectCurrentDocument);

  try {
    yield call(validateDocument, formUUID);

    yield put(
      validateDocumentEvaluationSuccess({
        data: {
          ...currentDocument,
          [`${role}ValidationOn`]: new Date().toISOString()
        }
      })
    );
  } catch (error: any) {
    const { message } = error.response.data;

    yield put(validateDocumentEvaluationError({ error: message, payload: message }));
  }
}

export default function* currentDocumentSaga() {
  yield takeLatest(loadDocument.type, getEmployeeDocumentsSaga);
  yield takeLatest(updateDocument.type, updateEmployeeDocumentSaga);
  yield takeLatest(setDiscussionDate.type, setDiscussionDateSaga);
  yield takeLatest(removeDocumentCoach.type, removeDocumentCoachSaga);
  yield takeLatest(addGlobalCompetenceEvaluation.type, addGlobalCompetenceEvaluationSaga);
  yield takeLatest(addDocumentEvaluation.type, addDocumentEvaluationSaga);
  yield takeLatest(validateDocumentEvaluation.type, validateDocumentEvaluationSaga);
}
