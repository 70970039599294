import { createSelector } from "reselect";

import { RootState } from "../../store/store";

const selectCurrentAppState = (state: RootState) => state.currentApp;

export const selectCyclesList = createSelector(
  selectCurrentAppState,
  (state) => state.cycles
);

export const selectCurrentCycle = createSelector(
  selectCurrentAppState,
  (state) => state.currentCycle
);

export const selectModalConfig = createSelector(
  selectCurrentAppState,
  (state) => state.modal
);

export const selectNotificationConfig = createSelector(
  selectCurrentAppState,
  (state) => state.notification
);
export const selectObjectiveTypes = createSelector(
  selectCurrentAppState,
  (state) => state.objectiveTypes
);

export const selectGenericCompetences = createSelector(
  selectCurrentAppState,
  (state) => state.genericCompetences
);

export const selectFunctionalCompetences = createSelector(
  selectCurrentAppState,
  (state) => state.functionalCompetences
);

export const selectDevelopmentActionThemes = createSelector(
  selectCurrentAppState,
  (state) => state.developmentActionThemes
);

export const selectValueTypes = createSelector(
  selectCurrentAppState,
  (state) => state.valueTypes
);

export const selectObjectiveScores = createSelector(
  selectCurrentAppState,
  (state) => state.objectiveScores
);

export const selectCompetenceScores = createSelector(
  selectCurrentAppState,
  (state) => state.competenceScores
);

export const appIsInitialized = createSelector(
  selectCurrentAppState,
  (state) =>
    state.cycles?.length &&
    state.objectiveTypes?.length &&
    state.developmentActionThemes?.length &&
    state.valueTypes?.length
);
