import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  ChevronRightIcon,
  InformationCircleIcon
} from "@heroicons/react/20/solid";

import { SupportedLanguages } from "../../models/LangField";
import { InfoLink } from "../../utils/links";

type OwnProps = {
  breadcrumbs: string[];
  lang: SupportedLanguages;
  linkUrl?: string;
  linkLabel?: string;
  infoLinks?: InfoLink[];
};

const SubNavBar: FC<OwnProps> = (props): ReactElement => {
  const { t: tCommon } = useTranslation();
  const { breadcrumbs, lang, linkUrl, linkLabel, infoLinks } = props;
  const [first, ...rest] = breadcrumbs;

  const relatedPhase = "target";
  return (
    <header className="bg-slate-200 shadow-sm">
      <div className="mx-auto max-w-7xl flex flex-wrap items-center justify-between px-4 py-2 sm:px-6 lg:px-8">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4 py-2">
            <li>
              <div className="flex">
                <span className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  {first}
                </span>
              </div>
            </li>
            {rest.map((item) => (
              <li key={item}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 capitalize">
                    {item}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </nav>

        {linkUrl && linkLabel && (
          <div className="flex">
            {relatedPhase === "target" && (
              <span className="sm:ml-3">
                <a
                  href={linkUrl}
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InformationCircleIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  {linkLabel}
                </a>
              </span>
            )}
          </div>
        )}

        {infoLinks && infoLinks.length > 0 && (
          <div className="flex">
            {infoLinks.map((infoLink) => (
              <span
                className="sm:ml-3"
                key={infoLink.name}
              >
                <a
                  href={infoLink.links[lang]}
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  {tCommon(`navigation.${infoLink.name}`)}
                </a>
              </span>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default SubNavBar;
