import { LangField } from '../LangField';
import { PersonName } from '../User';
import { CompetenceScore } from './CompetenceScore';

export enum CompetenceType {
  FUNCTIONAL = "functional",
  GENERIC = "generic",
  SPECIFIC = "soft-skill"
}

export type GenericCompetence = {
  _id: string;
  name: LangField;
  definition: LangField;
  behaviors: LangField;
};

export type FunctionalCompetence = {
  _id: string;
  name: LangField;
  definition: LangField;
  behaviors: LangField;
  isActive: boolean;
};

export type CompetenceEvaluation = {
  score: CompetenceScore;
  comment: string;
  modifiedAt: string;
};

export type BaseCompetenceEvaluations = {
  employee: CompetenceEvaluation;
  manager: CompetenceEvaluation;
  coach: CompetenceEvaluation;
};

export type BaseCompetence = {
  _id: string;

  // Basic informations
  relatedType: CompetenceType;
  genericCompetence?: GenericCompetence;
  functionalCompetence?: FunctionalCompetence;

  // Additional informations
  title: string;
  options?: {
    link?: string;
  };

  // Comments
  description: string;
  comments: string;

  // Notes
  notes: string[];

  // Evaluation
  evaluation: BaseCompetenceEvaluations;

  // Internal management
  createdBy: {
    _id: string;
    name: PersonName;
  };
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
};

export type RawBaseCompetence = {
  // Basic informations
  relatedType: string;
  genericCompetence?: string;
  functionalCompetence?: string;

  // Additional informations
  title: string;
  options?: {
    link?: string;
  };

  // Comments
  description: string;
  comments?: string;
};

export type RawCompetenceEvaluation = {
  score: string;
  comment: string;
};