import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

import { baseUrl } from "../../../routes";
import { classNames } from "../../../utils/styles";

type OwnProps = {
  title?: string;
};

const PerformanceInnerNav: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "myPerformance"
  });
  const { documentId, section: currentRoute = "overview" } = useParams();
  const { title } = props;

  const innerRoutes = [
    {
      id: "overview",
      name: t("routes.overview")
    },
    {
      id: "objectives",
      name: t("routes.objectives")
    },
    {
      id: "competences",
      name: t("routes.competences")
    },
    {
      id: "development-actions",
      name: t("routes.devActions")
    },
    {
      id: "lead",
      name: t("routes.values")
    },
    {
      id: "career",
      name: t("routes.career")
    },
    {
      id: "conclusion",
      name: t("routes.conclusion")
    }
  ];

  const getPath = (routeId: string): string => {
    if (routeId === "overview") {
      return `${baseUrl}/performance/${documentId}`;
    } else return `${baseUrl}/performance/${documentId}/${routeId}`;
  };

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        {title && (
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h3>
        )}
      </div>
      <div className="mt-4">
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {innerRoutes.map((route) => (
              <NavLink
                key={route.id}
                to={getPath(route.id)}
                className={classNames(
                  route.id === currentRoute
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                )}
                aria-current={route.id === currentRoute ? "page" : undefined}
              >
                {route.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PerformanceInnerNav;
