import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { RiseUpCourseRegistration } from "../../../models/integrations/RiseUp/RiseUpCourseRegistration";
import Modal from "../../modal/Modal";
import CourseRegistrationItem from "./CourseRegistrationItem";

type OwnProps = {
  year: number;
  registrations: RiseUpCourseRegistration[];
  closeModal: () => void;
};

const CourseRegistrationHistoryModal: FC<OwnProps> = (props): ReactElement => {
  const { registrations, year, closeModal } = props;

  const { t } = useTranslation("translation", {
    keyPrefix: "riseUp"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="sm:flex sm:items-start">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t(`modal.registrationHistory.title`, { year })}
              </h2>
              <p className="mt-1 mb-2 text-sm leading-6 text-gray-600">
                {t(`modal.registrationHistory.subtitle`)}
              </p>

              <div className="my-5">
                {registrations.map((registration) => (
                  <CourseRegistrationItem
                    key={registration._id}
                    courseRegistration={registration}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

export default CourseRegistrationHistoryModal;
