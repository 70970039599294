import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../../models/Actions";
import { BaseCompetence, RawBaseCompetence, RawCompetenceEvaluation } from "../../../models/Form/BaseCompetence";
import { RequestStatus } from "../../../models/Request";

interface CurrentDocumentCompetencesState {
  status: RequestStatus;
  competences?: BaseCompetence[];
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentDocumentCompetencesState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentDocumentCompetencesSlice = createSlice({
  name: "currentDocumentCompetences",
  initialState,
  reducers: {
    // Load the document competences
    setDocumentCompetences(
      state,
      action: PayloadAction<{ competences: BaseCompetence[] }>
    ) {
      const { competences } = action.payload;
      state.competences = competences;
    },

    // Create a new competence for the current document.
    createDocumentCompetence(
      state,
      action: PayloadAction<{ formUUID: string; data: RawBaseCompetence }>
    ) {
      state.actionStatuses[createDocumentCompetence.type] = {
        status: RequestStatus.PENDING
      };
    },
    createDocumentCompetenceSuccess(
      state,
      action: PayloadAction<{ data: BaseCompetence }>
    ) {
      const { data } = action.payload;
      state.competences?.push(data);
      state.actionStatuses[createDocumentCompetence.type].status =
        RequestStatus.SUCCESS;
    },
    createDocumentCompetenceError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[createDocumentCompetence.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearCreateDocumentCompetenceStatus(state) {
      delete state.actionStatuses[createDocumentCompetence.type];
    },

    // Update an existing competence for the current document.
    updateDocumentCompetence(
      state,
      action: PayloadAction<{
        formUUID: string;
        competenceId: string;
        data: RawBaseCompetence;
      }>
    ) {
      state.actionStatuses[updateDocumentCompetence.type] = {
        status: RequestStatus.PENDING
      };
    },
    updateDocumentCompetenceSuccess(
      state,
      action: PayloadAction<{ data: BaseCompetence }>
    ) {
      const { data } = action.payload;
      const index = state.competences?.findIndex(
        (competence) => competence._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.competences?.splice(index, 1, data);
      }
      state.actionStatuses[updateDocumentCompetence.type].status =
        RequestStatus.SUCCESS;
    },
    updateDocumentCompetenceError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[updateDocumentCompetence.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearUpdateDocumentCompetenceStatus(state) {
      delete state.actionStatuses[updateDocumentCompetence.type];
    },

    // Delete an existing competence for the current document.
    deleteDocumentCompetence(
      state,
      action: PayloadAction<{ formUUID: string; competenceId: string }>
    ) {
      state.actionStatuses[deleteDocumentCompetence.type] = {
        status: RequestStatus.PENDING
      };
    },
    deleteDocumentCompetenceSuccess(
      state,
      action: PayloadAction<{ competenceId: string }>
    ) {
      const { competenceId } = action.payload;
      const index = state.competences?.findIndex(
        (competence) => competence._id === competenceId
      );
      if (index !== undefined && index !== -1) {
        state.competences?.splice(index, 1);
      }
      state.actionStatuses[deleteDocumentCompetence.type].status =
        RequestStatus.SUCCESS;
    },
    deleteDocumentCompetenceError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[deleteDocumentCompetence.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearDeleteDocumentCompetenceStatus(state) {
      delete state.actionStatuses[deleteDocumentCompetence.type];
    },

    // Add evaluation to a competence for the current document.
    addDocumentCompetenceEvaluation(
      state,
      action: PayloadAction<{
        formUUID: string;
        competenceId: string;
        data: RawCompetenceEvaluation;
      }>
    ) {
      state.actionStatuses[addDocumentCompetenceEvaluation.type] = {
        status: RequestStatus.PENDING
      };
    },
    addDocumentCompetenceEvaluationSuccess(
      state,
      action: PayloadAction<{ data: BaseCompetence }>
    ) {
      const { data } = action.payload;
      const index = state.competences?.findIndex(
        (competence) => competence._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.competences?.splice(index, 1, data);
      }
      state.actionStatuses[addDocumentCompetenceEvaluation.type].status =
        RequestStatus.SUCCESS;
    },
    addDocumentCompetenceEvaluationError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[addDocumentCompetenceEvaluation.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearAddDocumentCompetenceEvaluationStatus(state) {
      delete state.actionStatuses[addDocumentCompetenceEvaluation.type];
    }
  }
});

export const {
  setDocumentCompetences,
  createDocumentCompetence,
  createDocumentCompetenceSuccess,
  createDocumentCompetenceError,
  clearCreateDocumentCompetenceStatus,
  updateDocumentCompetence,
  updateDocumentCompetenceSuccess,
  updateDocumentCompetenceError,
  clearUpdateDocumentCompetenceStatus,
  deleteDocumentCompetence,
  deleteDocumentCompetenceSuccess,
  deleteDocumentCompetenceError,
  clearDeleteDocumentCompetenceStatus,
  addDocumentCompetenceEvaluation,
  addDocumentCompetenceEvaluationSuccess,
  addDocumentCompetenceEvaluationError,
  clearAddDocumentCompetenceEvaluationStatus
} = currentDocumentCompetencesSlice.actions;

export default currentDocumentCompetencesSlice.reducer;
