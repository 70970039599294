import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/20/solid";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import ActionButton from "../buttons/ActionButton";

type OwnProps = {
  onClick?: () => void;
};

const EmptyMeetingsList: FC<OwnProps> = (props): ReactElement => {
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { t } = useTranslation("translation", {
    keyPrefix: "meetings"
  });
  const { onClick } = props;

  return (
    <div className="text-center m-10">
      <CalendarDaysIcon className="mx-auto h-12 w-12 text-teal-500" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {t("noData.title")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">{t("noData.subtitle")}</p>
      {onClick && (
        <div className="mt-6">
          <ActionButton
            label={tCommon("actions.addMeeting")}
            icon={PlusIcon}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyMeetingsList;
