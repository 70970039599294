import { FC, ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { PuzzlePieceIcon, RocketLaunchIcon } from "@heroicons/react/24/outline";

import { FormRole } from "../../models/Form/BaseForm";
import { selectCurrentDocumentCompetences } from "../../redux/currentDocument/competences/selectors";
import { selectCurrentDocumentObjectives } from "../../redux/currentDocument/objectives/selectors";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  competences: selectCurrentDocumentCompetences
});

type OwnProps = {
  role: FormRole;
};

type Props = OwnProps & ReduxProps;

const DocumentEvaluationProgress: FC<Props> = (props): ReactElement => {
  const { competences, objectives, role } = props;

  const remainingObjectivesToScore = objectives?.filter(
    (objective) => objective.evaluation?.[role]?.score != null
  ).length;

  const remainingCompetencesToScore = competences?.filter(
    (competence) => competence.evaluation?.[role]?.score != null
  ).length;

  return (
    <div className="overflow-hidden rounded-l">
      <dl className="flex flex-none gap-x-8 sm:w-auto">
        <div className="flex w-full gap-x-2.5 justify-center">
          <dt>
            <RocketLaunchIcon
              className="h-6 w-6 text-teal-500"
              aria-hidden="true"
            />
          </dt>
          <dd className="text-sm leading-6 text-gray-900">
            {remainingObjectivesToScore ?? 0} / {objectives?.length ?? 0}
          </dd>
          <dt>
            <PuzzlePieceIcon
              className="h-6 w-6 text-orange-400"
              aria-hidden="true"
            />
          </dt>
          <dd className="text-sm leading-6 text-gray-900">
            {remainingCompetencesToScore ?? 0} / {competences?.length ?? 0}
          </dd>
        </div>
      </dl>
    </div>
  );
};

const withConnect = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentEvaluationProgress);
