import { isBefore } from "date-fns";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useQueryClient } from "@tanstack/react-query";

import { useMeetings } from "../../hooks/meetings/useMeetings";
import { BaseForm, FormRole } from "../../models/Form/BaseForm";
import { SupportedLanguages } from "../../models/LangField";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { selectCurrentDocumentCompetences } from "../../redux/currentDocument/competences/selectors";
import { selectCurrentDocumentObjectives } from "../../redux/currentDocument/objectives/selectors";
import {
  isLoadingValidateDocumentEvaluation,
  selectValidateDocumentEvaluationError,
  selectValidateDocumentEvaluationStatus
} from "../../redux/currentDocument/selectors";
import {
  clearValidateDocumentEvaluationStatus,
  validateDocumentEvaluation
} from "../../redux/currentDocument/slice";
import { parseISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import WarningBanner from "../banners/WarningBanner";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import SafeHTMLText from "../texts/SafeHTMLText";
import DocumentEvaluationScores from "./DocumentEvaluationScores";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  competences: selectCurrentDocumentCompetences,
  isLoading: isLoadingValidateDocumentEvaluation,
  status: selectValidateDocumentEvaluationStatus,
  formError: selectValidateDocumentEvaluationError
});

const mapDispatchToProps = {
  validateDocument: validateDocumentEvaluation,
  clearStatus: clearValidateDocumentEvaluationStatus,
  notify: showNotification
};

interface OwnProps {
  document: BaseForm;
  role: FormRole;
  lang: SupportedLanguages;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const DocumentValidationModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "form.evaluation"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    document,
    objectives,
    competences,
    role,
    lang,
    isLoading,
    status,
    formError,
    validateDocument,
    clearStatus,
    notify,
    closeModal
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Get meetings for the document
  const { data: meetings = [] } = useMeetings(document.formUUID);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.validationSucess"),
        message: t("validation.messages.success")
      });

      // Cleanup
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", document.formUUID]
      });
      closeModal();
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    document,
    notify,
    closeModal,
    t,
    tCommon
  ]);

  const hasScheduledMeetings = useMemo(() => {
    // Parse each meeting and check if there is at least one scheduled and
    // that already happened
    return meetings.some((meeting) => {
      const meetingDate = parseISODateStr(meeting.meetingDate);

      return meetingDate != null && isBefore(meetingDate, new Date());
    });
  }, [meetings]);

  const objectiveWeightsTotal = useMemo(() => {
    return (
      objectives?.reduce((acc, objective) => acc + objective.weight, 0) ?? 0
    );
  }, [objectives]);

  const remainingObjectivesToScore = useMemo(() => {
    return (
      objectives?.filter(
        (objective) => objective.evaluation?.[role]?.score == null
      ).length ?? 0
    );
  }, [objectives, role]);

  const remainingCompetencesToScore = useMemo(() => {
    return (
      competences?.filter(
        (competence) => competence.evaluation?.[role]?.score == null
      ).length ?? 0
    );
  }, [competences, role]);
  // Shared validation
  const canValidate = useMemo(() => {
    return (
      objectiveWeightsTotal === 100 &&
      remainingObjectivesToScore === 0 &&
      remainingCompetencesToScore === 0
    );
  }, [
    objectiveWeightsTotal,
    remainingObjectivesToScore,
    remainingCompetencesToScore
  ]);

  // Manager validation
  const isValid = useMemo(() => {
    return role === FormRole.manager
      ? canValidate && hasScheduledMeetings
      : canValidate;
  }, [role, canValidate, hasScheduledMeetings]);

  const globalObjectiveScore = useMemo(() => {
    return (
      objectives?.reduce((acc, objective) => {
        const weight = objective?.weight ?? 0;
        const scoreWeight = objective.evaluation?.manager?.scoreWeight ?? 0;
        return acc + (weight * scoreWeight) / 100;
      }, 0) ?? 0
    );
  }, [objectives]);

  const globalCompetenceScore = useMemo(() => {
    return document?.evaluation?.globalCompetence?.score;
  }, [document]);

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="sm:flex sm:items-start">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t(`validation.title.${role}`)}
              </h2>
              {isValid ? (
                <div>
                  <h3 className="my-3 font-semibold leading-6 text-indigo-600">
                    {t("validation.congratulations")}
                  </h3>
                  <SafeHTMLText
                    html={t(`validation.subtitle.${role}`, {
                      year: document?.relatedCycle.year
                    })}
                    className="mt-1 mb-2 text-sm leading-6 text-gray-600"
                  />
                  <DocumentEvaluationScores
                    lang={lang}
                    objectiveScore={globalObjectiveScore}
                    competenceScore={globalCompetenceScore}
                  />
                  <SafeHTMLText
                    html={t("validation.note")}
                    className="mt-5 text-sm font-semibold leading-6 text-gray-600"
                  />
                </div>
              ) : (
                <div>
                  <h3 className="my-3 font-semibold leading-6 text-red-600">
                    {t("validation.error.title")}
                  </h3>
                  <SafeHTMLText
                    html={t("validation.error.subtitle")}
                    className="mt-1 mb-2 text-sm leading-6 text-gray-600"
                  />
                  {objectiveWeightsTotal !== 100 && (
                    <div className="mt-5">
                      <ErrorBanner
                        message={t(
                          `validation.error.objectiveWeightsTotal.${role}`,
                          {
                            total: objectiveWeightsTotal
                          }
                        )}
                        isHtml
                      />
                    </div>
                  )}
                  {role === FormRole.manager && !hasScheduledMeetings && (
                    <div className="mt-5">
                      <ErrorBanner
                        message={t(
                          `validation.error.hasScheduledMeetings.${role}`
                        )}
                      />
                    </div>
                  )}
                  {remainingObjectivesToScore > 0 && (
                    <div className="mt-5">
                      <ErrorBanner
                        message={t(
                          "validation.error.remainingObjectivesToScore",
                          {
                            count: remainingObjectivesToScore
                          }
                        )}
                        isHtml
                      />
                    </div>
                  )}
                  {remainingCompetencesToScore > 0 && (
                    <div className="mt-5">
                      <ErrorBanner
                        message={t(
                          "validation.error.remainingCompetencesToScore",
                          {
                            count: remainingCompetencesToScore
                          }
                        )}
                        isHtml
                      />
                    </div>
                  )}
                  {/* <SafeHTMLText
                    html={t("validation.error.note")}
                    className="mt-5 text-sm leading-6 text-gray-600"
                  /> */}
                  <div className="mt-5">
                    <WarningBanner message={t("validation.warning")} isHtml />
                  </div>
                </div>
              )}
              {errorMessage != null && (
                <div className="mt-5">
                  <ErrorBanner message={errorMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className={classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
              isLoading || !isValid ? "cursor-not-allowed opacity-50 ml-2" : ""
            )}
            disabled={isLoading || !isValid || !document}
            onClick={() => {
              if (document) {
                validateDocument({ formUUID: document.formUUID, role });
              }
            }}
          >
            {isLoading && <Spinner size="small" />}
            {t("validation.confirmButton")}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentValidationModal);
