import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import ActivityLogsList from "../../../../components/activities/ActivityLogsList";
import ActionButton from "../../../../components/buttons/ActionButton";
import NotesList from "../../../../components/notes/NotesList";
import { ActionMode } from "../../../../models/Actions";
import { BaseCompetence } from "../../../../models/Form/BaseCompetence";
import { BaseForm, Phase } from "../../../../models/Form/BaseForm";
import { ActivityLogResourceType } from "../../../../models/Logs/ActivityLog";
import { NotificationConfig } from "../../../../models/Notification";
import { User } from "../../../../models/User";
import CompetenceEvaluations from "./CompetenceEvaluations";
import CompetenceHeader from "./CompetenceHeader";
import CompetenceMeta from "./CompetenceMeta";

type OwnProps = {
  currentUser: User;
  employeeId: string;
  document: BaseForm;
  competence: BaseCompetence;
  showManagerEvaluation: boolean;
  mode?: ActionMode;
  notify: (notification: NotificationConfig) => void;
  openModal: (modal: any) => void;
};

const PerformanceCompetenceDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const {
    currentUser,
    employeeId,
    document,
    competence,
    showManagerEvaluation,
    mode = ActionMode.READ_ONLY,
    notify,
    openModal
  } = props;
  const { formUUID: documentId, relatedManager, relatedEmployee } = document;
  const isOwner = employeeId === competence.createdBy._id;
  const isManager = employeeId === relatedManager._id;
  const isEmployee = employeeId === relatedEmployee._id;

  const openEditObjectiveModal = () => {
    openModal({
      modal: {
        component: "EditCompetenceModal",
        props: {
          documentId,
          employeeId,
          competence
        }
      }
    });
  };

  const openRemoveCompetenceModal = () => {
    openModal({
      modal: {
        component: "RemoveCompetenceModal",
        props: {
          documentId,
          competenceId: competence._id
        }
      }
    });
  };

  return (
    <>
      <main className="py-10">
        <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
          <CompetenceHeader
            competence={competence}
            onEdit={
              mode === ActionMode.EDIT
                ? () => openEditObjectiveModal()
                : undefined
            }
          />
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            <CompetenceMeta competence={competence} lang={currentUser.lang} />

            {document.relatedPhase === Phase.review && (
              <CompetenceEvaluations
                document={document}
                competence={competence}
                isManager={isManager}
                isEmployee={isEmployee}
              />
            )}

            {/* Comments */}
            <NotesList
              currentUser={currentUser}
              documentId={documentId}
              employeeId={employeeId}
              resourceType="competence"
              resourceId={competence._id}
              mode={mode}
              notify={notify}
            />
          </div>

          <div>
            {(showManagerEvaluation || isManager) && (
              <ActivityLogsList
                documentId={documentId}
                resourceType={ActivityLogResourceType.COMPETENCE}
                resourceId={competence._id}
              />
            )}
            {(isOwner || isManager) && mode === ActionMode.EDIT && (
              <div className="mt-4">
                <ActionButton
                  label={t("actions.delete")}
                  onClick={openRemoveCompetenceModal}
                  colorVariant="red"
                />
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default PerformanceCompetenceDetail;
