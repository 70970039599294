import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  id: string;
  icon: any;
  label: string;
  score: string;
  color?: string;
  isPercent?: boolean;
};

const DocumentEvaluationScore: FC<OwnProps> = (props): ReactElement => {
  const { id, icon: Icon, label, score, color, isPercent = false } = props;

  return (
    <div
      key={id}
      className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
    >
      <dt>
        <div
          className={classNames(
            "absolute rounded-md p-3",
            color
              ? color === "orange"
                ? "bg-orange-400"
                : `bg-${color}-500`
              : "bg-indigo-500"
          )}
        >
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {label}
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline justify-between lg:flex sm:pb-6">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          <p className="text-2xl font-semibold text-gray-900">
            {score}
            {isPercent ? "%" : ""}
          </p>
        </div>
      </dd>
    </div>
  );
};

export default DocumentEvaluationScore;
