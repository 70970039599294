import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { enUS, fr, nl } from 'date-fns/locale';

import { SupportedLanguages } from '../models/LangField';

export const formatISODateStr = (
  date: string,
  formatOutput?: string
): string => {
  const isoDate = parseISO(date);

  return format(isoDate, formatOutput ?? "P", { locale: fr });
};

export const formatISODateStrFromNow = (date: string): string => {
  const isoDate = parseISO(date);

  return formatDistanceToNow(isoDate);
};

export const parseISODateStr = (date: string): Date => {
  return parseISO(date);
};

export const formatDateToStr = (
  date: Date,
  lang: SupportedLanguages = "fr",
  formatOutput?: string
): string => {
  const locale = lang === "fr" ? fr : lang === "nl" ? nl : enUS;
  return format(date, formatOutput ?? "P", { locale });
};
