import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/20/solid";

import { useMeetings } from "../../hooks/meetings/useMeetings";
import { ActionMode } from "../../models/Actions";
import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { FormMeeting } from "../../models/Form/FormMeeting";
import { NotificationConfig } from "../../models/Notification";
import EmptyMeetingsList from "./EmptyMeetingsList";
import MeetingListItem from "./MeetingListItem";

type OwnProps = {
  document: BaseForm;
  employee?: Employee;
  mode: ActionMode;
  notify: (notification: NotificationConfig) => void;
  openModal: (modal: any) => void;
};

const MeetingsList: FC<OwnProps> = (props): ReactElement => {
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { document, employee, mode, notify, openModal } = props;

  const { data: meetings = [] } = useMeetings(document.formUUID);

  const openMeetingDateModal = (meeting?: FormMeeting) => {
    openModal({
      modal: {
        component: "MeetingDateModal",
        props: {
          action: meeting != null ? "edit" : "add",
          documentId: document.formUUID,
          phase: document.relatedPhase,
          meeting,
          defaultParticipant:
            document.relatedManager?._id === employee?._id
              ? document.relatedEmployee
              : document.relatedManager,
          additionalParticipants: document.performanceCoachs
        }
      }
    });
  };

  const openDeleteMeetingModal = (meeting: FormMeeting) => {
    openModal({
      modal: {
        component: "DeleteMeetingModal",
        props: {
          documentId: document.formUUID,
          meeting,
          notify
        }
      }
    });
  };

  return (
    <div className="lg:col-span-6 xl:col-span-6">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Upcoming meetings
        </h2>
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-gray-900"
          onClick={() => openMeetingDateModal()}
        >
          <PlusIcon
            className="-ml-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {tCommon("actions.addMeeting")}
        </button>
      </div>
      <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6">
        {meetings.map((meeting) => (
          <MeetingListItem
            key={meeting._id}
            meeting={meeting}
            canEdit={
              meeting.createdBy._id === employee?._id &&
              mode === ActionMode.EDIT
            }
            openEditModal={(meeting: FormMeeting) =>
              openMeetingDateModal(meeting)
            }
            openDeleteModal={openDeleteMeetingModal}
          />
        ))}
        {meetings.length === 0 && (
          <EmptyMeetingsList onClick={() => openMeetingDateModal()} />
        )}
      </ol>
    </div>
  );
};

export default MeetingsList;
