import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorAction } from '../../../models/Actions';
import { BaseValue, RawBaseValue, RawBaseValueEvaluation } from '../../../models/Form/BaseValue';
import { RequestStatus } from '../../../models/Request';

interface CurrentDocumentValuesState {
  status: RequestStatus;
  values?: BaseValue[];
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentDocumentValuesState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentDocumentValuesSlice = createSlice({
  name: "currentDocumentValues",
  initialState,
  reducers: {
    // Load the document values
    setDocumentValues(state, action: PayloadAction<{ values: BaseValue[] }>) {
      const { values } = action.payload;
      state.values = values;
    },

    // Create a new value for the current document
    createDocumentValue(
      state,
      action: PayloadAction<{ formUUID: string; data: RawBaseValue }>
    ) {
      state.actionStatuses[createDocumentValue.type] = {
        status: RequestStatus.PENDING
      };
    },
    createDocumentValueSuccess(
      state,
      action: PayloadAction<{ data: BaseValue }>
    ) {
      const { data } = action.payload;
      state.values?.push(data);
      state.actionStatuses[createDocumentValue.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    createDocumentValueError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[createDocumentValue.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearCreateDocumentValueStatus(state) {
      delete state.actionStatuses[createDocumentValue.type];
    },

    // Update an existing value for the current document
    updateDocumentValue(
      state,
      action: PayloadAction<{
        formUUID: string;
        valueId: string;
        data: RawBaseValue;
      }>
    ) {
      state.actionStatuses[updateDocumentValue.type] = {
        status: RequestStatus.PENDING
      };
    },
    updateDocumentValueSuccess(
      state,
      action: PayloadAction<{ data: BaseValue }>
    ) {
      const { data } = action.payload;
      const index = state.values?.findIndex(
        (value) => value._id === data._id
      ) as number;

      if (index !== undefined && index !== -1) {
        state.values?.splice(index, 1, data);
      }
      state.actionStatuses[updateDocumentValue.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    updateDocumentValueError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[updateDocumentValue.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearUpdateDocumentValueStatus(state) {
      delete state.actionStatuses[updateDocumentValue.type];
    },

    // Delete an existing value for the current document
    deleteDocumentValue(
      state,
      action: PayloadAction<{ formUUID: string; valueId: string }>
    ) {
      state.actionStatuses[deleteDocumentValue.type] = {
        status: RequestStatus.PENDING
      };
    },
    deleteDocumentValueSuccess(
      state,
      action: PayloadAction<{ valueId: string }>
    ) {
      const { valueId } = action.payload;
      const index = state.values?.findIndex((value) => value._id === valueId);
      if (index !== undefined && index !== -1) {
        state.values?.splice(index, 1);
      }
      state.actionStatuses[deleteDocumentValue.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    deleteDocumentValueError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[deleteDocumentValue.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearDeleteDocumentValueStatus(state) {
      delete state.actionStatuses[deleteDocumentValue.type];
    },

    // Add evaluation to a value for the current document.
    addDocumentValueEvaluation(
      state,
      action: PayloadAction<{
        formUUID: string;
        valueId: string;
        data: RawBaseValueEvaluation;
      }>
    ) {
      state.actionStatuses[addDocumentValueEvaluation.type] = {
        status: RequestStatus.PENDING
      };
    },
    addDocumentValueEvaluationSuccess(
      state,
      action: PayloadAction<{ data: BaseValue }>
    ) {
      const { data } = action.payload;
      const index = state.values?.findIndex(
        (value) => value._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.values?.splice(index, 1, data);
      }
      state.actionStatuses[addDocumentValueEvaluation.type].status =
        RequestStatus.SUCCESS;
    },
    addDocumentValueEvaluationError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[addDocumentValueEvaluation.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearAddDocumentValueEvaluationStatus(state) {
      delete state.actionStatuses[addDocumentValueEvaluation.type];
    }
  }
});

export const {
  setDocumentValues,
  createDocumentValue,
  createDocumentValueSuccess,
  createDocumentValueError,
  clearCreateDocumentValueStatus,
  updateDocumentValue,
  updateDocumentValueSuccess,
  updateDocumentValueError,
  clearUpdateDocumentValueStatus,
  deleteDocumentValue,
  deleteDocumentValueSuccess,
  deleteDocumentValueError,
  clearDeleteDocumentValueStatus,
  addDocumentValueEvaluation,
  addDocumentValueEvaluationSuccess,
  addDocumentValueEvaluationError,
  clearAddDocumentValueEvaluationStatus
} = currentDocumentValuesSlice.actions;

export default currentDocumentValuesSlice.reducer;
