import { FC, Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from "@headlessui/react";
import {
  BellIcon,
  PresentationChartLineIcon,
  UsersIcon
} from "@heroicons/react/24/outline";

import { selectCurrentUser } from "../../redux/currentUser/selectors";
import { baseUrl } from "../../routes";
import { classNames } from "../../utils/styles";
import LanguageSelector from "../selectors/LanguageSelector";
import UserAvatar from "../users/UserAvatar";

type NavigationItem = {
  name: string;
  href: string;
  icon?: any;
  newTab?: boolean;
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = {};

type OwnProps = ReduxProps;

const TopNavBarNew: FC<OwnProps> = (props): ReactElement => {
  const { t, i18n } = useTranslation();
  const { currentUser } = props;
  const navigation: NavigationItem[] = [
    {
      name: t("navigation.myPerformance"),
      href: `${baseUrl}/my-performance`,
      icon: PresentationChartLineIcon
    },
    {
      name: t("navigation.teamlist"),
      href: `${baseUrl}/teamlist`,
      icon: UsersIcon
    }
  ];

  const userNavigation: NavigationItem[] = [];
  if (currentUser?.isAdmin ?? false) {
    userNavigation.push({
      name: t("navigation.admin"),
      href: `/admin`,
      newTab: true
    });
  }

  userNavigation.push(
    { name: t("navigation.myProfile"), href: `${baseUrl}/profile` },
    { name: t("navigation.settings"), href: `${baseUrl}/settings` },
    { name: t("navigation.logout"), href: "/auth/logout" }
  );

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({ open }) => (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-11 w-auto"
                  src="/logo-partena.png"
                  alt="Partena logo"
                />
              </div>
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((item) => (
                  <NavLink
                    to={item.href}
                    key={item.name}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "border-indigo-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "inline-flex items-center border-b-2 px-1 pt-1 text-base font-medium capitalize"
                      )
                    }
                    // aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <div className="mr-5">
                <LanguageSelector initialLanguage={i18n.language ?? "en"} />
              </div>
              <button
                type="button"
                className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    {currentUser ? (
                      <UserAvatar
                        name={currentUser.name}
                        pictureUrl={currentUser.avatarUrl}
                        size={8}
                        showName
                      />
                    ) : null}
                  </MenuButton>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        {({ focus }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              focus ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            target={item.newTab ? "_blank" : "_self"}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(TopNavBarNew);
