import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import CompetenceTypeBadge from "../../../../components/competences/CompetenceTypeBadge";
import LinkedCourseRegistrationsList from "../../../../components/integrations/riseUp/LinkedCourseRegistrationsList";
import UserAvatar from "../../../../components/users/UserAvatar";
import { useAddCourseRegistrationToDevAction } from "../../../../hooks/integrations/riseUp/useAddCourseRegistrationToDevAction";
import { useRemoveCourseRegistrationFromDevAction } from "../../../../hooks/integrations/riseUp/useRemoveCourseRegistrationFromDevAction";
import { ActionMode } from "../../../../models/Actions";
import { BaseDevelopmentAction } from "../../../../models/Form/BaseDevelopmentAction";
import { RiseUpCourseRegistration } from "../../../../models/integrations/RiseUp/RiseUpCourseRegistration";
import { SupportedLanguages } from "../../../../models/LangField";
import {
  NotificationConfig,
  NotificationType
} from "../../../../models/Notification";
import {
  addDocumentDevActionCourseRegistration,
  removeDocumentDevActionCourseRegistration
} from "../../../../redux/currentDocument/devActions/slice";
import { getAvatarUrl } from "../../../../utils/user";

const mapDispatchToProps = {
  addCourseRegistration: addDocumentDevActionCourseRegistration,
  removeCourseRegistration: removeDocumentDevActionCourseRegistration
};

type OwnProps = {
  documentId: string;
  devAction: BaseDevelopmentAction;
  lang: SupportedLanguages;
  mode: ActionMode;
  memberId?: string;
  showBeta?: boolean;
  notify: (notification: NotificationConfig) => void;
};

const DevActionMeta: FC<OwnProps & ReduxProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "devActions"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    devAction,
    lang = "fr",
    mode,
    memberId,
    showBeta = false,
    notify,
    addCourseRegistration,
    removeCourseRegistration
  } = props;

  const mutationLinkRegistration = useAddCourseRegistrationToDevAction(
    documentId,
    devAction._id
  );
  const mutationUnlinkRegistration = useRemoveCourseRegistrationFromDevAction(
    documentId,
    devAction._id
  );

  const onLinkCourseRegistration = (registration: RiseUpCourseRegistration) => {
    if (registration != null) {
      mutationLinkRegistration.mutate(registration._id);
      addCourseRegistration({ devActionId: devAction._id, registration });

      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.saveSuccess"),
        message: tCommon("notifications.courseRegistrationLinked")
      });
    }
  };

  const onUnlinkCourseRegistration = (
    registration: RiseUpCourseRegistration
  ) => {
    if (registration != null) {
      mutationUnlinkRegistration.mutate(registration._id);
      removeCourseRegistration({
        devActionId: devAction._id,
        registrationId: registration._id
      });

      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.saveSuccess"),
        message: tCommon("notifications.courseRegistrationUnlinked")
      });
    }
  };

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.type")}
              </dt>
              <dd className="mt-1 text-xl font-semibold text-indigo-600">
                {devAction.relatedType.name[lang]}
              </dd>
            </div>
            {devAction.relatedMentor && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.mentor")}
                </dt>
                <dd className="mt-1 text-xl font-semibold text-indigo-600">
                  <UserAvatar
                    name={devAction.relatedMentor.name}
                    pictureUrl={getAvatarUrl(
                      devAction.relatedMentor?.holaMember
                    )}
                  />
                </dd>
              </div>
            )}
            {devAction?.relatedObjective && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.objective")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 font-medium">
                  {devAction?.relatedObjective?.title ??
                    devAction?.relatedObjective?.relatedType?.name?.[
                      lang ?? "fr"
                    ] ??
                    "N/A"}
                </dd>
              </div>
            )}
            {devAction?.relatedCompetence && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.competence")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 font-medium">
                  {devAction?.relatedCompetence?.title}
                  {devAction?.relatedCompetence?.relatedType && (
                    <CompetenceTypeBadge
                      type={devAction.relatedCompetence.relatedType}
                    />
                  )}
                </dd>
              </div>
            )}
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.description")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {devAction.description}
              </dd>
            </div>
            {devAction.comments && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.comments")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {devAction.comments}
                </dd>
              </div>
            )}
            {showBeta && devAction.courseRegistrations?.length > 0 && (
              <div className="sm:col-span-3">
                <LinkedCourseRegistrationsList
                  courseRegistrations={devAction.courseRegistrations}
                  label={t("riseUp.courseRegistrations")}
                  memberId={memberId}
                  onLinkRegistration={
                    mode === ActionMode.EDIT
                      ? onLinkCourseRegistration
                      : undefined
                  }
                  onUnlinkRegistration={
                    mode === ActionMode.EDIT
                      ? onUnlinkCourseRegistration
                      : undefined
                  }
                />
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DevActionMeta);
