import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import ActivityLogsList from "../../../../components/activities/ActivityLogsList";
import ActionButton from "../../../../components/buttons/ActionButton";
import NotesList from "../../../../components/notes/NotesList";
import { ActionMode } from "../../../../models/Actions";
import { BaseForm, Phase } from "../../../../models/Form/BaseForm";
import { BaseValue } from "../../../../models/Form/BaseValue";
import { ActivityLogResourceType } from "../../../../models/Logs/ActivityLog";
import { NotificationConfig } from "../../../../models/Notification";
import { User } from "../../../../models/User";
import ValueEvaluations from "./ValueEvaluations";
import ValueHeader from "./ValueHeader";
import ValueMeta from "./ValueMeta";

type OwnProps = {
  currentUser: User;
  employeeId: string;
  document: BaseForm;
  value: BaseValue;
  showManagerEvaluation: boolean;
  mode?: ActionMode;
  notify: (notification: NotificationConfig) => void;
  openModal: (modal: any) => void;
};

const PerformanceValueDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const {
    currentUser,
    employeeId,
    document,
    value,
    showManagerEvaluation,
    mode = ActionMode.READ_ONLY,
    notify,
    openModal
  } = props;
  const { formUUID: documentId, relatedEmployee, relatedManager } = document;
  const isOwner = employeeId === value.createdBy._id;
  const isManager = employeeId === relatedManager._id;
  const isEmployee = employeeId === relatedEmployee._id;

  const openEditValueModal = () => {
    openModal({
      modal: {
        component: "EditValueModal",
        props: {
          documentId,
          value
        }
      }
    });
  };

  const openRemoveValueModal = () => {
    openModal({
      modal: {
        component: "RemoveValueModal",
        props: {
          documentId,
          valueId: value._id
        }
      }
    });
  };

  return (
    <>
      <main className="py-10">
        <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
          <ValueHeader
            value={value}
            // onEdit={
            //   mode === ActionMode.EDIT ? () => openEditValueModal() : undefined
            // }
          />
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            <ValueMeta value={value} lang={currentUser.lang} />

            {document.relatedPhase === Phase.review && (
              <ValueEvaluations
                document={document}
                value={value}
                isManager={isManager}
                isEmployee={isEmployee}
              />
            )}

            {/* Comments */}
            <NotesList
              currentUser={currentUser}
              documentId={documentId}
              employeeId={employeeId}
              resourceType="value"
              resourceId={value._id}
              mode={mode}
              notify={notify}
            />
          </div>

          <div>
            {(showManagerEvaluation || isManager) && (
              <ActivityLogsList
                documentId={documentId}
                resourceType={ActivityLogResourceType.VALUE}
                resourceId={value._id}
              />
            )}
            {/* {isOwner && mode === ActionMode.EDIT && (
              <div className="mt-4">
                <ActionButton
                  label={t("actions.delete")}
                  onClick={openRemoveValueModal}
                  colorVariant="red"
                />
              </div>
            )} */}
          </div>
        </div>
      </main>
    </>
  );
};

export default PerformanceValueDetail;
