import Axios, { CancelTokenSource } from 'axios';

import { call, put, takeLatest } from '@redux-saga/core/effects';

import { BaseObjective } from '../../../models/Form/BaseObjective';
import {
  completeObjective, createNewObjective, deleteObjective, uncompleteObjective, updateObjective,
  updateObjectiveScore
} from '../../../services/ObjectiveService';
import {
  addDocumentObjectiveEvaluation, addDocumentObjectiveEvaluationError,
  addDocumentObjectiveEvaluationSuccess, completeDocumentObjective,
  completeDocumentObjectiveError, completeDocumentObjectiveSuccess, createDocumentObjective,
  createDocumentObjectiveError, createDocumentObjectiveSuccess, deleteDocumentObjective,
  deleteDocumentObjectiveError, deleteDocumentObjectiveSuccess, uncompleteDocumentObjective,
  uncompleteDocumentObjectiveError, uncompleteDocumentObjectiveSuccess, updateDocumentObjective,
  updateDocumentObjectiveError, updateDocumentObjectiveSuccess
} from './slice';

function* createDocumentObjectiveSaga(
  action: ReturnType<typeof createDocumentObjective>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;

  try {
    const response: BaseObjective = yield call(
      createNewObjective,
      signal.token,
      formUUID,
      data
    );

    yield put(createDocumentObjectiveSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      createDocumentObjectiveError({
        error: message,
        payload: message
      })
    );
  }
}

function* updateDocumentObjectiveSaga(
  action: ReturnType<typeof updateDocumentObjective>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, objectiveId, data } = action.payload;

  try {
    const response: BaseObjective = yield call(
      updateObjective,
      signal.token,
      formUUID,
      objectiveId,
      data
    );

    yield put(updateDocumentObjectiveSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      updateDocumentObjectiveError({
        error: message,
        payload: message
      })
    );
  }
}

function* completeDocumentObjectiveSaga(
  action: ReturnType<typeof completeDocumentObjective>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, objectiveId } = action.payload;

  try {
    const response: BaseObjective = yield call(
      completeObjective,
      signal.token,
      formUUID,
      objectiveId
    );

    yield put(completeDocumentObjectiveSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      completeDocumentObjectiveError({
        error: message,
        payload: message
      })
    );
  }
}

function* uncompleteDocumentObjectiveSaga(
  action: ReturnType<typeof uncompleteDocumentObjective>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, objectiveId } = action.payload;

  try {
    const response: BaseObjective = yield call(uncompleteObjective, signal.token, formUUID, objectiveId);

    yield put(uncompleteDocumentObjectiveSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      uncompleteDocumentObjectiveError({
        error: message,
        payload: message
      })
    );
  }
}

function* deleteDocumentObjectiveSaga(
  action: ReturnType<typeof deleteDocumentObjective>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, objectiveId } = action.payload;

  try {
    yield call(deleteObjective, signal.token, formUUID, objectiveId);

    yield put(deleteDocumentObjectiveSuccess({ objectiveId }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      deleteDocumentObjectiveError({
        error: message,
        payload: message
      })
    );
  }
}

function* addDocumentObjectiveEvaluationSaga(
  action: ReturnType<typeof addDocumentObjectiveEvaluation>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, objectiveId, data } = action.payload;

  try {
    const response: BaseObjective = yield call(
      updateObjectiveScore,
      signal.token,
      formUUID,
      objectiveId,
      data
    );

    yield put(addDocumentObjectiveEvaluationSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      addDocumentObjectiveEvaluationError({
        error: message,
        payload: message
      })
    );
  }
}

export default function* currentDocumentObjectiveSaga() {
  yield takeLatest(createDocumentObjective.type, createDocumentObjectiveSaga);
  yield takeLatest(updateDocumentObjective.type, updateDocumentObjectiveSaga);
  yield takeLatest(
    completeDocumentObjective.type,
    completeDocumentObjectiveSaga
  );
  yield takeLatest(uncompleteDocumentObjective.type, uncompleteDocumentObjectiveSaga);
  yield takeLatest(deleteDocumentObjective.type, deleteDocumentObjectiveSaga);
  yield takeLatest(
    addDocumentObjectiveEvaluation.type,
    addDocumentObjectiveEvaluationSaga
  );
}
