import { createSelector } from 'reselect';

import { RequestStatus } from '../../../models/Request';
import { RootState } from '../../../store/store';
import {
  addDocumentObjectiveEvaluation, completeDocumentObjective, createDocumentObjective,
  deleteDocumentObjective, uncompleteDocumentObjective, updateDocumentObjective
} from './slice';

const selectCurrentDocumentObjectivesState = (state: RootState) =>
  state.currentDocument.objectives;

export const selectCurrentDocumentObjectives = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.objectives
);

export const isLoadingCreateDocumentObjective = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[createDocumentObjective.type]?.status ===
    RequestStatus.PENDING
);

export const selectCreateDocumentObjectiveStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[createDocumentObjective.type]?.status
);

export const selectCreateDocumentObjectiveError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[createDocumentObjective.type]?.error
);

export const isLoadingUpdateDocumentObjective = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[updateDocumentObjective.type]?.status ===
    RequestStatus.PENDING
);

export const selectUpdateDocumentObjectiveStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[updateDocumentObjective.type]?.status
);

export const selectUpdateDocumentObjectiveError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[updateDocumentObjective.type]?.error
);

export const isLoadingCompleteDocumentObjective = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[completeDocumentObjective.type]?.status ===
    RequestStatus.PENDING
);

export const selectCompleteDocumentObjectiveStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[completeDocumentObjective.type]?.status
);

export const selectCompleteDocumentObjectiveError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[completeDocumentObjective.type]?.error
);

export const isLoadingUncompleteDocumentObjective = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[uncompleteDocumentObjective.type]?.status ===
    RequestStatus.PENDING
);

export const selectUncompleteDocumentObjectiveStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[uncompleteDocumentObjective.type]?.status
);

export const selectUncompleteDocumentObjectiveError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[uncompleteDocumentObjective.type]?.error
);

export const isLoadingDeleteDocumentObjective = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[deleteDocumentObjective.type]?.status ===
    RequestStatus.PENDING
);

export const selectDeleteDocumentObjectiveStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[deleteDocumentObjective.type]?.status
);

export const selectDeleteDocumentObjectiveError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) => state.actionStatuses[deleteDocumentObjective.type]?.error
);

export const isLoadingAddDocumentObjectiveEvaluation = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[addDocumentObjectiveEvaluation.type]?.status ===
    RequestStatus.PENDING
);

export const selectAddDocumentObjectiveEvaluationStatus = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[addDocumentObjectiveEvaluation.type]?.status
);

export const selectAddDocumentObjectiveEvaluationError = createSelector(
  selectCurrentDocumentObjectivesState,
  (state) =>
    state.actionStatuses[addDocumentObjectiveEvaluation.type]?.error
);