import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type ColorVariant = "indigo" | "blue" | "red" | "green" | "yellow" | "purple";

const getColorClasses = (colorVariant: ColorVariant): string => {
  const colorMap = {
    indigo:
      "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
    blue: "bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600",
    red: "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600",
    green: "bg-green-600 hover:bg-green-500 focus-visible:outline-green-600",
    yellow:
      "bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600",
    purple: "bg-purple-600 hover:bg-purple-500 focus-visible:outline-purple-600"
  };

  return colorMap[colorVariant];
};

type OwnProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  colorVariant?: ColorVariant;
  onClick: () => void;
};

const ActionButton: FC<OwnProps> = (props): ReactElement => {
  const {
    disabled = false,
    label,
    icon: Icon,
    colorVariant = "indigo",
    onClick
  } = props;

  const colorClasses = getColorClasses(colorVariant);

  return (
    <button
      type="button"
      className={classNames(
        `inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`,
        colorClasses,
        disabled ? "opacity-50 cursor-not-allowed" : ""
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
      {label}
    </button>
  );
};

export default ActionButton;
