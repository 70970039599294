import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import InputCheckbox from "../../../../components/forms/InputCheckbox";
import InputRichText from "../../../../components/forms/InputRichText";
import { BaseDevelopmentAction } from "../../../../models/Form/BaseDevelopmentAction";
import { NotificationType } from "../../../../models/Notification";
import { UserRole } from "../../../../models/User";
import { showNotification } from "../../../../redux/currentApp/slice";
import {
  isLoadingAddDocumentDevActionEvaluation,
  selectAddDocumentDevActionEvaluationError,
  selectAddDocumentDevActionEvaluationStatus
} from "../../../../redux/currentDocument/devActions/selectors";
import {
  addDocumentDevActionEvaluation,
  clearAddDocumentDevActionEvaluationStatus
} from "../../../../redux/currentDocument/devActions/slice";
import { selectCurrentEmployeeLang } from "../../../../redux/currentEmployee/selectors";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingAddDocumentDevActionEvaluation,
  status: selectAddDocumentDevActionEvaluationStatus,
  formError: selectAddDocumentDevActionEvaluationError,
  lang: selectCurrentEmployeeLang
});

const mapDispatchToProps = {
  addEvaluation: addDocumentDevActionEvaluation,
  clearStatus: clearAddDocumentDevActionEvaluationStatus,
  notify: showNotification
};

type OwnProps = {
  documentId: string;
  devAction: BaseDevelopmentAction;
  isManager: boolean;
  isEmployee: boolean;
  onCancel: () => void;
};

type Props = OwnProps & ReduxProps;

const EditDevActionEvaluation: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "devActions"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    documentId,
    devAction,
    isManager,
    isEmployee,
    onCancel,
    isLoading,
    status,
    formError,
    lang,
    addEvaluation,
    clearStatus,
    notify
  } = props;
  const role: UserRole = isManager
    ? UserRole.MANAGER
    : isEmployee
      ? UserRole.EMPLOYEE
      : UserRole.COACH;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      clearStatus();
      onCancel();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    documentId,
    notify,
    onCancel,
    t,
    tCommon
  ]);

  const initialState = {
    comment: devAction.evaluation?.[role].comment ?? "",
    keepForNextCycle: devAction.evaluation?.[role].keepForNextCycle ?? false
  };

  const validationSchema = Yup.object({
    comment: Yup.string()
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (values.comment != null) {
          addEvaluation({
            formUUID: documentId,
            devActionId: devAction._id,
            data: {
              ...values
            }
          });
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="sm:flex sm:items-start">
            <div className="space-y-12 w-full">
              <div className="border-b border-gray-900/10 pb-5 mb-5">
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Short description */}
                  <div className="col-span-full">
                    <div className="mb-3">
                      <span className="text-sm font-semibold text-grey-600">
                        {t(`evaluation.commentLabel.${role}`)}
                      </span>
                    </div>
                    <InputRichText
                      innerRef={editorRef}
                      label={t(`evaluation.comment`)}
                      name="comment"
                      initialValue={initialState.comment}
                      onChange={(value: string) => {
                        setFieldValue("comment", value);
                      }}
                      helperText={t(`evaluation.${role}.helpers.comment`)}
                    />
                  </div>
                  {/* keep for next cycle */}
                  {role === UserRole.EMPLOYEE && (
                    <div className="col-span-full mb-5">
                      <InputCheckbox
                        name="keepForNextCycle"
                        label={t(`evaluation.keepForNextCycle`)}
                        helperText={t(`evaluation.keepForNextCycleHelper`)}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-3 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isSubmitting}
                    onClick={onCancel}
                  >
                    {tCommon("actions.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    {tCommon("actions.save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditDevActionEvaluation);
