const baseUrl = "https://performance-help.synapze.be/en/articles";

export type InfoLink = {
  name: string;
  links: {
    [key: string]: string;
  };
};

export const guideLinks = {
  en: `${baseUrl}/8816735-guide-target-setting-2024`,
  fr: `${baseUrl}/8816735-guide-target-setting-2024`,
  nl: `${baseUrl}/8816720-gids-target-setting-2024`
};

export const infoLinks = [{
  name: "guide",
  links: {
    en: `${baseUrl}/8816735-guide-target-setting-2024`,
    fr: `${baseUrl}/8816735-guide-target-setting-2024`,
    nl: `${baseUrl}/8816720-gids-target-setting-2024`
  }
}];

export const infoLinksReview: InfoLink[] = [
  {
    name: "inspiration",
    links: {
      en: `${baseUrl}/8703490-questions-d-inspiration`,
      fr: `${baseUrl}/8703490-questions-d-inspiration`,
      nl: `${baseUrl}/8688103-vragen-ter-inspiratie`
    }
  },
  {
    name: "roadmap",
    links: {
      en: `${baseUrl}/8703702-feuille-de-route`,
      fr: `${baseUrl}/8703702-feuille-de-route`,
      nl: `${baseUrl}/8703595-stappenplan`
    }
  }, {
    name: "faq",
    links: {
      en: `${baseUrl}/6885383-faq-performance-review-2022-en`,
      fr: `${baseUrl}/6885383-faq-performance-review-2022-fr`,
      nl: `${baseUrl}/6885383-faq-performance-review-2022-nl`
    }
  }];

export const statusLinks = {
  en: `${baseUrl}/8818785-document-status-fr`,
  fr: `${baseUrl}/8818785-document-status-fr`,
  nl: `${baseUrl}/8818799-document-status-nl`
};

export const coachLinks = {
  en: `${baseUrl}/8823634-performance-coach`,
  fr: `${baseUrl}/8823634-performance-coach`,
  nl: `${baseUrl}/8823651-performance-coach`
};

export const objectiveLinks = {
  en: `${baseUrl}/8816514-definition-des-objectifs`,
  fr: `${baseUrl}/8816514-definition-des-objectifs`,
  nl: `${baseUrl}/8816492-definitie-van-objectieven`
};

export const competenceLinks = {
  en: `${baseUrl}/8816708-definition-des-competences`,
  fr: `${baseUrl}/8816708-definition-des-competences`,
  nl: `${baseUrl}/8816698-definitie-van-competenties`
};

export const developmentActionLinks = {
  en: `${baseUrl}/6966898-definir-les-actions-de-developpement`,
  fr: `${baseUrl}/6966898-definir-les-actions-de-developpement`,
  nl: `${baseUrl}/6968325-vastleggen-van-ontwikkelacties`
};

export const valueLinks = {
  en: `${baseUrl}/8816738-definition-des-dimensions-lead`,
  fr: `${baseUrl}/8816738-definition-des-dimensions-lead`,
  nl: `${baseUrl}/8816737-definitie-van-lead-dimensies`
};
