import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { DialogTitle } from "@headlessui/react";
import { HandRaisedIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingCompleteDocumentObjective, selectCompleteDocumentObjectiveError,
  selectCompleteDocumentObjectiveStatus
} from "../../redux/currentDocument/objectives/selectors";
import {
  clearCompleteDocumentObjectiveStatus, completeDocumentObjective
} from "../../redux/currentDocument/objectives/slice";
import ErrorBanner from "../banners/ErrorBanner";
import DefaultButton from "../buttons/DefaultButton";
import WarningButton from "../buttons/WarningButton";
import Modal from "../modal/Modal";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingCompleteDocumentObjective,
  status: selectCompleteDocumentObjectiveStatus,
  error: selectCompleteDocumentObjectiveError
});

const mapDispatchToProps = {
  completeObjective: completeDocumentObjective,
  clearStatus: clearCompleteDocumentObjectiveStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  objectiveId: string;
  closeModal: () => void;
}

const CompleteObjectiveModal: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    objectiveId,
    isLoading,
    error,
    status,
    clearStatus,
    completeObjective,
    closeModal,
    notify
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (error) {
      setErrorMessage(error);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("setAsCompleted.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    error,
    closeModal,
    clearStatus,
    documentId,
    notify,
    t,
    tCommon,
    queryClient
  ]);

  const onCompleteHandler = () => {
    completeObjective({ formUUID: documentId, objectiveId });
  };

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
              <HandRaisedIcon
                className="h-6 w-6 text-orange-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <DialogTitle
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {t("setAsCompleted.title")}
              </DialogTitle>
              <div className="mt-2">
                <p className="text-sm font-semibold text-gray-500">
                  {t("setAsCompleted.confirmation")}
                </p>
                <p className="mt-3 text-sm text-gray-500">
                  {t("setAsCompleted.confirmationMessage")}
                </p>
              </div>
              {errorMessage != null && (
                <div className="mt-5">
                  <ErrorBanner message={errorMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <WarningButton
            label={t("actions.setAsCompleted")}
            onClick={onCompleteHandler}
          />
          <DefaultButton
            label={tCommon("actions.cancel")}
            onClick={() => closeModal()}
            customClass="mr-3"
          />
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(CompleteObjectiveModal);
