import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import SafeHTMLText from "../../../../components/texts/SafeHTMLText";
import UserAvatar from "../../../../components/users/UserAvatar";
import { BaseForm } from "../../../../models/Form/BaseForm";
import { selectCurrentEmployeeLang } from "../../../../redux/currentEmployee/selectors";
import { formatISODateStr } from "../../../../utils/dates";
import { getAvatarUrl } from "../../../../utils/user";
import EditGlobalCompetenceEvaluation from "./EditGlobalCompetenceEvaluation";

const mapStateToProps = createStructuredSelector({
  lang: selectCurrentEmployeeLang
});

type OwnProps = {
  document: BaseForm;
  canEdit: boolean;
};

type Props = OwnProps & ReduxProps;

const GlobalCompetenceEvaluation: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "form"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });

  const { canEdit, document, lang } = props;
  const { formUUID: documentId, evaluation, relatedManager } = document;
  const globalCompetence = evaluation.globalCompetence;
  const [isEditing, setIsEditing] = useState(
    canEdit && globalCompetence?.score == null
  );

  return (
    <section aria-labelledby="evaluation-item" className="mb-5">
      <div className="bg-white shadow sm:rounded-lg py-5 sm:px-6 border-2 border-orange-200">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2
              id="evaluation-item-title"
              className="text-lg font-medium text-orange-600"
            >
              {t("evaluation.globalCompetence.title")}
            </h2>
          </div>
        </div>
        {!isEditing && globalCompetence != null ? (
          <div
            key={document._id}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
          >
            <div>
              <UserAvatar
                name={relatedManager?.name ?? ""}
                pictureUrl={getAvatarUrl(relatedManager?.holaMember)}
                showName
              />
              <h3 className="text-lg font-medium text-gray-900">
                {globalCompetence?.score?.name?.[lang!]}
              </h3>
              <span className="text-xs text-gray-500 italic leading-tight">
                {globalCompetence?.score?.definition?.[lang!]}
              </span>
              <div className="mt-3 text-sm text-gray-700">
                <SafeHTMLText html={globalCompetence?.comment} />
              </div>
              <div className="mt-2 space-x-2 text-xs flex items-center">
                <span className="font-medium text-gray-500">
                  <time
                    className="ml-1 font-medium text-gray-900"
                    dateTime={globalCompetence?.modifiedAt}
                  >
                    {formatISODateStr(globalCompetence?.modifiedAt)}
                  </time>
                </span>
              </div>
            </div>
            {canEdit && (
              <div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => setIsEditing(true)}
                >
                  {tCommon("actions.edit")}
                </button>
              </div>
            )}
          </div>
        ) : (
          <EditGlobalCompetenceEvaluation
            documentId={documentId}
            globalCompetence={globalCompetence}
            onCancel={() => setIsEditing(false)}
          />
        )}
      </div>
    </section>
  );
};

const withConnect = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(GlobalCompetenceEvaluation);
