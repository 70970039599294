import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import InputRichText from "../../../../components/forms/InputRichText";
import { BaseForm } from "../../../../models/Form/BaseForm";
import { NotificationType } from "../../../../models/Notification";
import { UserRole } from "../../../../models/User";
import { showNotification } from "../../../../redux/currentApp/slice";
import {
  isLoadingAddDocumentEvaluation,
  selectAddDocumentEvaluationError,
  selectAddDocumentEvaluationStatus
} from "../../../../redux/currentDocument/selectors";
import {
  addDocumentEvaluation,
  clearAddDocumentEvaluationStatus
} from "../../../../redux/currentDocument/slice";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingAddDocumentEvaluation,
  status: selectAddDocumentEvaluationStatus,
  formError: selectAddDocumentEvaluationError
});

const mapDispatchToProps = {
  addEvaluation: addDocumentEvaluation,
  clearStatus: clearAddDocumentEvaluationStatus,
  notify: showNotification
};

type OwnProps = {
  document: BaseForm;
  isManager: boolean;
  isEmployee: boolean;
  onCancel: () => void;
};

type Props = OwnProps & ReduxProps;

const EditPerformanceConclusionEvaluation: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "conclusion"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    document,
    isManager,
    isEmployee,
    onCancel,
    isLoading,
    status,
    formError,
    addEvaluation,
    clearStatus,
    notify
  } = props;
  const role: UserRole = isManager
    ? UserRole.MANAGER
    : isEmployee
      ? UserRole.EMPLOYEE
      : UserRole.COACH;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      clearStatus();
      onCancel();
      queryClient.invalidateQueries({
        queryKey: ["activities", document.formUUID]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    document.formUUID,
    notify,
    onCancel,
    t,
    tCommon
  ]);

  const initialState = {
    comment: document.evaluation?.[role].comment ?? ""
  };

  const validationSchema = Yup.object({
    comment: Yup.string()
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (values.comment != null) {
          addEvaluation({
            formUUID: document.formUUID,
            role,
            data: {
              ...document.evaluation?.[role],
              ...values
            }
          });
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="sm:flex sm:items-start">
            <div className="space-y-12 w-full">
              <div className="border-b border-gray-900/10 pb-5 mb-5">
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Short description */}
                  <div className="col-span-full">
                    <div className="mb-3">
                      <span className="text-sm font-semibold text-grey-600">
                        {t(`evaluation.commentLabel.${role}`)}
                      </span>
                    </div>
                    <InputRichText
                      innerRef={editorRef}
                      label={t(`evaluation.comment`)}
                      name="comment"
                      initialValue={initialState.comment}
                      onChange={(value: string) => {
                        setFieldValue("comment", value);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isSubmitting}
                    onClick={onCancel}
                  >
                    {tCommon("actions.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    {t(`evaluation.saveComment`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditPerformanceConclusionEvaluation);
