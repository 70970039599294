import { createSelector } from 'reselect';

import { RequestStatus } from '../../../models/Request';
import { RootState } from '../../../store/store';
import {
  addDocumentValueEvaluation, createDocumentValue, deleteDocumentValue, updateDocumentValue
} from './slice';

const selectCurrentDocumentValuesState = (state: RootState) =>
  state.currentDocument.values;

export const selectCurrentDocumentValues = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.values
);

export const isLoadingCreateDocumentValue = createSelector(
  selectCurrentDocumentValuesState,
  (state) =>
    state.actionStatuses[createDocumentValue.type]?.status ===
    RequestStatus.PENDING
);

export const selectCreateDocumentValueStatus = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[createDocumentValue.type]?.status
);

export const selectCreateDocumentValueError = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[createDocumentValue.type]?.error
);

export const isLoadingUpdateDocumentValue = createSelector(
  selectCurrentDocumentValuesState,
  (state) =>
    state.actionStatuses[updateDocumentValue.type]?.status ===
    RequestStatus.PENDING
);

export const selectUpdateDocumentValueStatus = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[updateDocumentValue.type]?.status
);

export const selectUpdateDocumentValueError = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[updateDocumentValue.type]?.error
);

export const isLoadingDeleteDocumentValue = createSelector(
  selectCurrentDocumentValuesState,
  (state) =>
    state.actionStatuses[deleteDocumentValue.type]?.status ===
    RequestStatus.PENDING
);

export const selectDeleteDocumentValueStatus = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[deleteDocumentValue.type]?.status
);

export const selectDeleteDocumentValueError = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[deleteDocumentValue.type]?.error
);

export const isLoadingAddDocumentValueEvaluation = createSelector(
  selectCurrentDocumentValuesState,
  (state) =>
    state.actionStatuses[addDocumentValueEvaluation.type]?.status ===
    RequestStatus.PENDING
);

export const selectAddDocumentValueEvaluationStatus = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[addDocumentValueEvaluation.type]?.status
);

export const selectAddDocumentValueEvaluationError = createSelector(
  selectCurrentDocumentValuesState,
  (state) => state.actionStatuses[addDocumentValueEvaluation.type]?.error
);