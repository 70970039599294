import { Employee } from "./Employee/Employee";
import { HolaMember } from "./integrations/HolaSpirit/HolaMember";
import { SupportedLanguages } from "./LangField";

export type PersonName = {
  first: string;
  last: string;
};

export interface User {
  name: PersonName;
  email: string;
  lang: SupportedLanguages;
  isAdmin: boolean;
  isStaff: boolean;
  isManager: boolean;
  isRegistered: boolean;
  isSpecialGuest?: boolean;
  createdAt: string;
  avatarUrl?: string;
  intercomUserHash?: string;
}

export interface UserData extends User {
  employeeData: Employee;
  holaMember?: HolaMember;
}

export enum UserRole {
  MANAGER = "manager",
  EMPLOYEE = "employee",
  COACH = "coach",
}