import isEmpty from "lodash/isEmpty";
import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import SafeHTMLText from "../../../../components/texts/SafeHTMLText";
import UserAvatar from "../../../../components/users/UserAvatar";
import { BaseDevelopmentAction } from "../../../../models/Form/BaseDevelopmentAction";
import { BaseForm } from "../../../../models/Form/BaseForm";
import { UserRole } from "../../../../models/User";
import { selectShowManagerEvaluation } from "../../../../redux/currentDocument/selectors";
import { formatISODateStr } from "../../../../utils/dates";
import { getAvatarUrl } from "../../../../utils/user";
import EditDevActionEvaluation from "./EditDevActionEvaluation";

const mapStateToProps = createStructuredSelector({
  showManagerEvaluation: selectShowManagerEvaluation
});

type OwnProps = {
  document: BaseForm;
  devAction: BaseDevelopmentAction;
  isManager: boolean;
  isEmployee: boolean;
};

type Props = OwnProps & ReduxProps;

const DevActionEvaluations: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "devActions"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });

  const { document, devAction, isManager, isEmployee, showManagerEvaluation } =
    props;
  const { formUUID: documentId, relatedEmployee, relatedManager } = document;
  const role: UserRole = isManager
    ? UserRole.MANAGER
    : isEmployee
      ? UserRole.EMPLOYEE
      : UserRole.COACH;
  const evaluation = devAction.evaluation?.[role];
  const [isEditing, setIsEditing] = useState(isEmpty(evaluation?.comment));
  const otherRoles = [
    UserRole.MANAGER,
    UserRole.EMPLOYEE,
    UserRole.COACH
  ].filter((r) => r !== role);

  return (
    <section aria-labelledby="evaluation-item">
      <div className="bg-white shadow sm:rounded-lg py-5 sm:px-6 border-2 border-orange-200">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2
              id="evaluation-item-title"
              className="text-lg font-medium text-orange-600"
            >
              {t(`evaluation.${role}.title`)}
            </h2>
          </div>
        </div>
        {!isEditing ? (
          <div
            key={devAction._id}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 my-5 sm:flex-nowrap border-b border-gray-900/10"
          >
            <div className="pb-5">
              <div className="text-sm text-gray-700">
                <SafeHTMLText html={evaluation.comment} />
              </div>
              {evaluation?.keepForNextCycle && (
                <div className="flex items-center my-5">
                  <ArrowRightCircleIcon
                    className="h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm text-gray-900 italic">
                    {t("evaluation.keepForNextCycle")}
                  </span>
                </div>
              )}
              {evaluation?.modifiedAt && (
                <div className="mt-2 space-x-2 text-xs flex items-center">
                  <span className="font-medium text-gray-500">
                    <time
                      className="ml-1 font-medium text-gray-900"
                      dateTime={evaluation?.modifiedAt}
                    >
                      {formatISODateStr(evaluation?.modifiedAt)}
                    </time>
                  </span>
                </div>
              )}
            </div>
            <div>
              <button
                type="button"
                className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => setIsEditing(true)}
              >
                {tCommon("actions.edit")}
              </button>
            </div>
          </div>
        ) : (
          <EditDevActionEvaluation
            documentId={documentId}
            devAction={devAction}
            isManager={isManager}
            isEmployee={isEmployee}
            onCancel={() => setIsEditing(false)}
          />
        )}
        {showManagerEvaluation ? (
          <div>
            {otherRoles.map((r) => {
              const otherEvaluation = devAction.evaluation?.[r];
              if (otherEvaluation == null || isEmpty(otherEvaluation.comment)) {
                return null;
              }

              // Get and display user behind the role
              let user = null;
              if (r === UserRole.EMPLOYEE) {
                user = relatedEmployee;
              }

              if (r === UserRole.MANAGER) {
                user = relatedManager;
              }

              return (
                <div key={`obj-${r}`}>
                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-2 mb-5">
                      <h2
                        id="evaluation-item-title"
                        className="text-lg font-medium text-orange-600"
                      >
                        {t(`evaluation.${role}.titleOther.${r}`)}
                      </h2>
                    </div>
                  </div>
                  <UserAvatar
                    name={user?.name!}
                    pictureUrl={getAvatarUrl(user?.holaMember)}
                    showName
                  />
                  <div className="text-sm text-gray-700">
                    <SafeHTMLText html={otherEvaluation.comment} />
                  </div>
                  {otherEvaluation?.keepForNextCycle && (
                    <div className="flex items-center my-5">
                      <ArrowRightCircleIcon
                        className="h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="ml-2 text-sm text-gray-900 italic">
                        {t("evaluation.keepForNextCycle")}
                      </span>
                    </div>
                  )}
                  {otherEvaluation?.modifiedAt && (
                    <div className="mt-2 space-x-2 text-xs flex items-center">
                      <span className="font-medium text-gray-500">
                        <time
                          className="ml-1 font-medium text-gray-900"
                          dateTime={otherEvaluation?.modifiedAt}
                        >
                          {formatISODateStr(otherEvaluation?.modifiedAt)}
                        </time>
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <InfoBanner message={t("evaluation.managerOnly")} />
          </div>
        )}
      </div>
    </section>
  );
};

const withConnect = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DevActionEvaluations);
