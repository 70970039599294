/**
 * This module contains functions to interact with the Objective API.
 * @packageDocumentation
 */

import { CancelToken } from "axios";

import { BaseObjective, ObjectiveType, RawBaseObjective, RawObjectiveEvaluation } from "../models/Form/BaseObjective";
import API, { ApiV1 } from "../utils/api";
import { ObjectiveScore } from "../models/Form/ObjectiveScore";

/**
 * Retrieves the list of objective types from the API.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @returns A promise that resolves to an array of ObjectiveType objects.
 */
export const getObjectiveTypes = async (
  cancelToken: CancelToken
): Promise<ObjectiveType[]> => {
  const url = `/objectivetypes`;

  try {
    const response = await ApiV1.get<{ data: ObjectiveType[] }>(url, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Creates a new objective.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param payload - The objective data.
 * @returns A promise that resolves to the created objective.
 */
export const createNewObjective = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseObjective
): Promise<BaseObjective> => {
  const url = `/form/${formUUID}/objective`;

  try {
    const response = await API.post<{ data: BaseObjective }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates an existing objective.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to update.
 * @param payload - The objective data.
 * @returns A promise that resolves to the updated objective.
 */
export const updateObjective = async (
  cancelToken: CancelToken,
  formUUID: string,
  objectiveId: string,
  payload: RawBaseObjective
): Promise<BaseObjective> => {
  const url = `/form/${formUUID}/objective/${objectiveId}`;

  try {
    const response = await API.put<{ data: BaseObjective }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Deletes an existing objective.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to delete.
 * @returns A promise that resolves when the objective is deleted.
 */
export const deleteObjective = async (
  cancelToken: CancelToken,
  formUUID: string,
  objectiveId: string
): Promise<void> => {
  const url = `/form/${formUUID}/objective/${objectiveId}`;

  try {
    await API.delete(url, {
      cancelToken,
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Marks an objective as complete.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to mark as complete.
 * @returns A promise that resolves when the objective is marked as complete.
 */
export const completeObjective = async (
  cancelToken: CancelToken,
  formUUID: string,
  objectiveId: string
): Promise<void> => {
  const url = `/form/${formUUID}/objective/${objectiveId}`;

  try {
    const response = await API.post(
      url,
      {},
      {
        cancelToken,
      }
    );

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Marks an objective as uncompleted.
 * @param cancelToken - A cancel token that can be used to cancel the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to mark as uncompleted.
 * @returns A promise that resolves when the objective is marked as uncompleted.
 */
export const uncompleteObjective = async (
  cancelToken: CancelToken,
  formUUID: string,
  objectiveId: string
): Promise<void> => {
  const url = `/form/${formUUID}/objective/${objectiveId}/uncomplete`;

  try {
    const response = await API.post(url, {}, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Adds an OKR to an objective.
 * @param signal - An AbortSignal object that can be used to abort the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to add the OKR to.
 * @param okrId - The UUID of the OKR to add.
 * @returns A promise that resolves when the OKR is added to the objective.
 */
export const addOKRToObjective = async (
  formUUID: string,
  objectiveId: string,
  okrId: string
): Promise<void> => {
  const url = `/form/${formUUID}/objective/${objectiveId}/okr`;

  try {
    await API.post(url, { okrId });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Removes an OKR from an objective.
 * @param signal - An AbortSignal object that can be used to abort the request.
 * @param formUUID - The UUID of the form to which the objective belongs.
 * @param objectiveId - The UUID of the objective to remove the OKR from.
 * @param okrId - The UUID of the OKR to remove.
 * @returns A promise that resolves when the OKR is removed from the objective.
 */
export const removeOKRFromObjective = async (
  formUUID: string,
  objectiveId: string,
  okrId: string
): Promise<void> => {
  const url = `/form/${formUUID}/objective/${objectiveId}/okr/${okrId}`;

  try {
    await API.delete(url);
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Fetches the objective scores from the API.
 *
 * @param cancelToken - The cancel token used to cancel the request.
 * @returns {Promise<ObjectiveScore[]>} A promise that resolves to an array of ObjectiveScore objects.
 * @throws Will throw an error if the API request fails.
 */
export const getObjectiveScores = async (
  cancelToken: CancelToken,
): Promise<ObjectiveScore[]> => {
  const url = `/objectivescores`;

  try {
    const response = await ApiV1.get<{ data: ObjectiveScore[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}

/**
 * Updates the score of a specific objective.
 *
 * @param cancelToken - The token used to cancel the request if needed.
 * @param formUUID - The unique identifier of the form.
 * @param objectiveId - The unique identifier of the objective.
 * @param payload - The data containing the new score for the objective.
 * @returns A promise that resolves to the updated objective.
 * @throws Will throw an error if the request fails.
 */
export const updateObjectiveScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  objectiveId: string,
  payload: RawObjectiveEvaluation
): Promise<BaseObjective> => {
  const url = `/form/${formUUID}/objective/${objectiveId}/score`;

  try {
    const response = await API.patch<{ data: BaseObjective }>(url, payload, {
      cancelToken,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};