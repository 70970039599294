import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import InputRichText from "../../../../components/forms/InputRichText";
import { BaseValue } from "../../../../models/Form/BaseValue";
import { NotificationType } from "../../../../models/Notification";
import { UserRole } from "../../../../models/User";
import { showNotification } from "../../../../redux/currentApp/slice";
import {
  isLoadingAddDocumentValueEvaluation,
  selectAddDocumentValueEvaluationError,
  selectAddDocumentValueEvaluationStatus
} from "../../../../redux/currentDocument/values/selectors";
import {
  addDocumentValueEvaluation,
  clearAddDocumentValueEvaluationStatus
} from "../../../../redux/currentDocument/values/slice";
import { selectCurrentEmployeeLang } from "../../../../redux/currentEmployee/selectors";
import SafeHTMLText from "../../../../components/texts/SafeHTMLText";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingAddDocumentValueEvaluation,
  status: selectAddDocumentValueEvaluationStatus,
  formError: selectAddDocumentValueEvaluationError,
  lang: selectCurrentEmployeeLang
});

const mapDispatchToProps = {
  addEvaluation: addDocumentValueEvaluation,
  clearStatus: clearAddDocumentValueEvaluationStatus,
  notify: showNotification
};

type OwnProps = {
  documentId: string;
  value: BaseValue;
  isManager: boolean;
  isEmployee: boolean;
  onCancel: () => void;
};

type Props = OwnProps & ReduxProps;

const EditValueEvaluation: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    documentId,
    value,
    isManager,
    isEmployee,
    onCancel,
    isLoading,
    status,
    formError,
    lang,
    addEvaluation,
    clearStatus,
    notify
  } = props;
  const role: UserRole = isManager
    ? UserRole.MANAGER
    : isEmployee
      ? UserRole.EMPLOYEE
      : UserRole.COACH;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      clearStatus();
      onCancel();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    documentId,
    notify,
    onCancel,
    t,
    tCommon
  ]);

  const initialState = {
    comment: value.evaluation?.[role].comment ?? ""
  };

  const validationSchema = Yup.object({
    comment: Yup.string()
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (values.comment != null) {
          addEvaluation({
            formUUID: documentId,
            valueId: value._id,
            data: {
              score: null,
              ...values
            }
          });
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="sm:flex sm:items-start">
            <div className="space-y-12 w-full">
              <div className="border-b border-gray-900/10 pb-5 mb-5">
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Short description */}
                  <div className="col-span-full">
                    <div className="mb-3">
                      <span className="text-sm font-semibold text-grey-600">
                        <SafeHTMLText html={t(`evaluation.commentLabel.${role}`)} />
                      </span>
                    </div>
                    <InputRichText
                      innerRef={editorRef}
                      label={t(`evaluation.comment`)}
                      name="comment"
                      initialValue={initialState.comment}
                      onChange={(value: string) => {
                        setFieldValue("comment", value);
                      }}
                      helperText={t(`evaluation.${role}.helpers.comment`)}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isSubmitting}
                    onClick={onCancel}
                  >
                    {tCommon("actions.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    {tCommon("actions.save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditValueEvaluation);
