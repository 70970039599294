import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import ActivityLogsList from "../../../../components/activities/ActivityLogsList";
import ActionButton from "../../../../components/buttons/ActionButton";
import NotesList from "../../../../components/notes/NotesList";
import { ActionMode } from "../../../../models/Actions";
import { BaseForm, Phase } from "../../../../models/Form/BaseForm";
import { BaseObjective } from "../../../../models/Form/BaseObjective";
import { ActivityLogResourceType } from "../../../../models/Logs/ActivityLog";
import { NotificationConfig } from "../../../../models/Notification";
import { User } from "../../../../models/User";
import ObjectiveEvaluations from "./ObjectiveEvaluations";
import ObjectiveHeader from "./ObjectiveHeader";
import ObjectiveMeta from "./ObjectiveMeta";

type OwnProps = {
  currentUser: User;
  employeeId: string;
  document: BaseForm;
  objective: BaseObjective;
  showManagerEvaluation: boolean;
  mode?: ActionMode;
  notify: (notification: NotificationConfig) => void;
  openModal: (modal: any) => void;
};

const PerformanceObjectiveDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const {
    currentUser,
    employeeId,
    document,
    objective,
    showManagerEvaluation,
    mode = ActionMode.READ_ONLY,
    notify,
    openModal
  } = props;
  const { formUUID: documentId, relatedManager, relatedEmployee } = document;
  const isOwner = employeeId === objective.createdBy._id;
  const isManager = employeeId === relatedManager._id;
  const isEmployee = employeeId === relatedEmployee._id;

  const openEditObjectiveModal = () => {
    openModal({
      modal: {
        component: "EditObjectiveModal",
        props: {
          documentId,
          objective,
          isManager,
          isOwner
        }
      }
    });
  };

  const openCompleteObjectiveModal = () => {
    openModal({
      modal: {
        component: "CompleteObjectiveModal",
        props: {
          documentId,
          objectiveId: objective._id
        }
      }
    });
  };

  const openUncompleteObjectiveModal = () => {
    openModal({
      modal: {
        component: "UncompleteObjectiveModal",
        props: {
          documentId,
          objectiveId: objective._id
        }
      }
    });
  };

  const openRemoveObjectiveModal = () => {
    openModal({
      modal: {
        component: "RemoveObjectiveModal",
        props: {
          documentId,
          objectiveId: objective._id
        }
      }
    });
  };

  return (
    <main className="py-10">
      <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <ObjectiveHeader
          objective={objective}
          onEdit={
            mode === ActionMode.EDIT
              ? () => openEditObjectiveModal()
              : undefined
          }
          onComplete={
            mode === ActionMode.EDIT
              ? () => openCompleteObjectiveModal()
              : undefined
          }
          onUncomplete={
            mode === ActionMode.EDIT &&
            objective.isCompleted &&
            (isManager || currentUser.isAdmin)
              ? () => openUncompleteObjectiveModal()
              : undefined
          }
        />
      </div>

      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <ObjectiveMeta
            documentId={document.formUUID}
            objective={objective}
            lang={currentUser.lang}
            mode={mode}
            memberId={document.relatedEmployee.holaMember?._id}
            notify={notify}
          />

          {document.relatedPhase === Phase.review && (
            <ObjectiveEvaluations
              document={document}
              objective={objective}
              isManager={isManager}
              isEmployee={isEmployee}
            />
          )}

          {/* Comments */}
          <NotesList
            currentUser={currentUser}
            documentId={documentId}
            employeeId={employeeId}
            resourceType="objective"
            resourceId={objective._id}
            mode={mode}
            notify={notify}
          />
        </div>

        <div>
          {(showManagerEvaluation || isManager) && (
            <ActivityLogsList
              documentId={documentId}
              resourceType={ActivityLogResourceType.OBJECTIVE}
              resourceId={objective._id}
            />
          )}
          {(isOwner || isManager) && mode === ActionMode.EDIT && (
            <div className="mt-4">
              <ActionButton
                label={t("actions.delete")}
                onClick={openRemoveObjectiveModal}
                colorVariant="red"
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default PerformanceObjectiveDetail;
